import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Sidebar from './Sidebar.js'
import Toolbar from '@material-ui/core/Toolbar';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Box, Grid } from '@material-ui/core';
import StatsPlayers from './StatsPlayers';
import StatsTeams from './StatsTeams';
import { Typography } from '@material-ui/core';
import StatsCurrentSeasonPlayers from './StatsCurrentSeasonPlayers';
import StatsItalyPlayers from './StatsItalyPlayers';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop: 0,
        paddingTop: 0,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'justify',
        color: theme.palette.text.secondary,
    },
    rightToolbar: {
        marginLeft: "auto",
        marginRight: -12
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
}

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

export default function StatsDetails() {
    const classes = useStyles();
    const [valueTab, setValueTab] = useState(0);

    const handleChange = (event, newValue) => {
        setValueTab(newValue);
    };
    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <Sidebar />
                    <Tabs
                        variant="fullWidth"
                        value={valueTab}
                        aria-label="nav tabs example"
                        onChange={handleChange}
                    >
                        <LinkTab label="Storiche" icon={<EqualizerIcon />} href="/statistiche-storiche" {...a11yProps(0)} />
                        <LinkTab label="Attuali" icon={<EqualizerIcon />} href="/statistiche-attuali" {...a11yProps(1)} />
                        <LinkTab label="Italia" icon={<EqualizerIcon />} href="/statistiche-nazionale" {...a11yProps(2)} />
                    </Tabs>
                </Toolbar>
            </AppBar>
            <TabPanel value={valueTab} index={0}>
                <Typography variant='h6'> Statistiche Campionato Cagliari Calcio dal 1947/48</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <StatsPlayers />
                    </Grid>
                    <Grid item xs={12}>
                        <StatsTeams />
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={valueTab} index={1}>
                <Typography variant='h6'> Statistiche Campionato Giocatori attualmente in rosa</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <StatsCurrentSeasonPlayers
                        />
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={valueTab} index={2}>
                <Typography variant='h6'> Statistiche Giocatori Nazionale Italiana (mentre indossavano la maglia del Cagliari)</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <StatsItalyPlayers
                        />
                    </Grid>
                </Grid>
            </TabPanel>
        </div>
    )
}