import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import parse from 'html-react-parser';

import * as ARBApi from '../../utils/ARBApi'

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FFFFFF",
    color: "#082D68"
  }
}));

//const HtmlToReact = require('html-to-react');
//const HtmlToReactParser = require('html-to-react').Parser;
//const ReactDOMServer = require('react-dom/server');

export default function MatchReport({ open, handleClose, title, report, played, opponentTeamId }) {
  const classes = useStyles();
  const descriptionElementRef = useRef(null);
  const [matchesWithTeam, setMatcheWithTeam] = useState([]);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    if (opponentTeamId > 0 && played === 'N') {
      ARBApi.getAllByID(`teams`, opponentTeamId).then((matchesWithTeam) => {
        setMatcheWithTeam(matchesWithTeam);
      });
    }
  }, [open, played, opponentTeamId]);

  if (opponentTeamId > 0 && matchesWithTeam && matchesWithTeam.length > 0 && played === 'N') {
    let stats = {
      "v": 0,
      "p": 0,
      "s": 0,
    }
    matchesWithTeam.forEach((match) => {
      if (match.gf === match.gc) {
        stats.p += 1;
      }
      else if (match.home === "Cagliari" && match.gc > match.gf) {
        stats.v += 1;
      }
      else if (match.away === "Cagliari" && match.gf > match.gc) {
        stats.v += 1;
      } else {
        stats.s += 1;
      }
    }, [stats]);
    report = `<div>
                <ul>
                  <li>Incontri disputati: ${matchesWithTeam.length}</li>
                  <li>Vinti: ${stats.v}</li>
                  <li>Pareggiati: ${stats.p}</li>
                  <li>Persi: ${stats.s}</li>
                  </ul>
                </div>`;
    title = `Precedenti ${title}`;
  }

  const reactHtml = parse(report);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" className={classes.title}>
          {title}
        </DialogTitle>
        <DialogContent>
          {reactHtml}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}