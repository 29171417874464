import React, { useState, useEffect, Fragment } from 'react';
import Waiting from './Waiting.js'
import { makeStyles } from '@material-ui/core/styles'
import * as ARBApi from '../../utils/ARBApi'
import Match from './Match'
import Players from './Players'
import HappenedMatches from './HappenedMatches'
import HappenedPlayers from './HappenedPlayers'
import RandomPlayer from './RandomPlayer'
import { Grid, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'justify',
    color: theme.palette.text.secondary,
  },
}));

export default function GridCards() {
  const classes = useStyles();
  const [lastMatch, setLastMatch] = useState([]);
  const [nextMatch, setNextMatch] = useState([]);
  const [appareances, setAppareances] = useState([]);
  const [goals, setGoals] = useState([]);
  const [goalKeepers, setGoalKeepers] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const [happenedTodayMatch, setHappenedTodayMatch] = useState([]);
  const [happenedTodayBirth, setHappenedTodayBirth] = useState([]);
  const [randomPlayer, setRandomPlayer] = useState([]);

  useEffect(() => {
    ARBApi.getAll(`lastMatch`).then((lastMatch) => {
      setLastMatch(lastMatch);
    });
    ARBApi.getAll(`nextMatch`).then((nextMatch) => {
      setNextMatch(nextMatch);
    });
    ARBApi.getAll(`appareances`).then((appareances) => {
      setAppareances(appareances);
    });
    ARBApi.getAll(`goals`).then((goals) => {
      setGoals(goals);
    });
    ARBApi.getAll(`goalkeepers`).then((goalKeepers) => {
      setGoalKeepers(goalKeepers);
    });
    ARBApi.getAll(`coachesAppareances`).then((coaches) => {
      setCoaches(coaches);
    });
    ARBApi.getAll(`happeneds`).then((happeneds) => {
      setHappenedTodayMatch(happeneds.matches.league);
      setHappenedTodayBirth(happeneds.players);
      setRandomPlayer(happeneds.player[0]);
    });
  }, []);

  return (
    <Fragment>
      {(lastMatch.length > 0) ? (
        <Grid item xs={12} sm={3}>
          <Paper className={classes.paper}>
            <Match
              match={lastMatch[0]}
              title='Ultima partita'
            />
          </Paper>
        </Grid>
      ) : (
        <Grid item xs={12} sm={3}>
          <Paper className={classes.paper}>
            <Waiting />
          </Paper>
        </Grid>
      )
      }
      {(nextMatch.length > 0) ? (
        <Grid item xs={12} sm={3}>
          <Paper className={classes.paper}>
            <Match
              match={nextMatch[0]}
              title='Prossima Partita'
            />
          </Paper>
        </Grid>
      ) : (
        <Grid item xs={12} sm={3}>
          <Paper className={classes.paper}>
            <Waiting />
          </Paper>
        </Grid>
      )
      }
      {(goals.length > 0) ? (
        <Grid item xs={12} sm={3}>
          <Paper className={classes.paper}>
            <Players
              players={goals}
              type='Gol'
            />
          </Paper>
        </Grid>
      ) : (
        <Grid item xs={12} sm={3}>
          <Paper className={classes.paper}>
            <Waiting />
          </Paper>
        </Grid>
      )
      }
      {(appareances.length > 0) ? (
        <Grid item xs={12} sm={3}>
          <Paper className={classes.paper}>
            <Players
              players={appareances}
              type='Presenze'
            />
          </Paper>
        </Grid>
      ) : (
        <Grid item xs={12} sm={3}>
          <Paper className={classes.paper}>
            <Waiting />
          </Paper>
        </Grid>
      )
      }
      {(goalKeepers.length > 0) ? (
        <Grid item xs={12} sm={3}>
          <Paper className={classes.paper}>
            <Players
              players={goalKeepers}
              type='Gol Subiti'
            />
          </Paper>
        </Grid>
      ) : (
        <Grid item xs={12} sm={3}>
          <Paper className={classes.paper}>
            <Waiting />
          </Paper>
        </Grid>
      )
      }
      {(coaches.length > 0) ? (
        <Grid item xs={12} sm={3}>
          <Paper className={classes.paper}>
            <Players
              players={coaches}
              type='Allenatori'
            />
          </Paper>
        </Grid>
      ) : (
        <Grid item xs={12} sm={3}>
          <Paper className={classes.paper}>
            <Waiting />
          </Paper>
        </Grid>
      )
      }
      {(happenedTodayMatch.length > 0) ? (
        <Grid item xs={12} sm={3}>
          <Paper className={classes.paper}>
            <HappenedMatches
              matches={happenedTodayMatch}
              title='Accadde Oggi - Partite'
            />
          </Paper>
        </Grid>
      ) : (
        <Grid item xs={12} sm={3}>
          <Paper className={classes.paper}>
            <Waiting />
          </Paper>
        </Grid>
      )
      }
      {(happenedTodayBirth.length > 0) ? (
        <Grid item xs={12} sm={3}>
          <Paper className={classes.paper}>
            <HappenedPlayers
              players={happenedTodayBirth}
              title='Nati Oggi'
            />
          </Paper>
        </Grid>
      ) : (
        <Grid item xs={12} sm={3}>
          <Paper className={classes.paper}>
            <Waiting />
          </Paper>
        </Grid>
      )
      }
      {(randomPlayer.ID) ? (
        <Grid item xs={12} sm={3}>
          <Paper className={classes.paper}>
            <RandomPlayer
              player={randomPlayer}
              type='Presenze'
              title='Uno a caso'
            />
          </Paper>
        </Grid>
      ) : (
        <Grid item xs={12} sm={3}>
          <Paper className={classes.paper}>
            <Waiting />
          </Paper>
        </Grid>
      )
      }
    </Fragment>
  );
}
