const allRoles =
    [
        {
            "id": "11",
            "description": "Attaccante-Seconda Punta",
            "role": "A"
        },
        {
            "id": "10",
            "description": "Attaccante-Prima Punta",
            "role": "A"
        },
        {
            "id": "9",
            "description": "Attaccante",
            "role": "A"
        },
        {
            "id": "8",
            "description": "Fantasista",
            "role": "C"
        },
        {
            "id": "7",
            "description": "Centrocampista-Laterale",
            "role": "C"
        },
        {
            "id": "6",
            "description": "Centrocampista-Centrale",
            "role": "C"
        },
        {
            "id": "5",
            "description": "Centrocampista",
            "role": "C"
        },
        {
            "id": "4",
            "description": "Difensore-Centrale",
            "role": "D"
        },
        {
            "id": "3",
            "description": "Difensore-Terzino",
            "role": "D"
        },
        {
            "id": "2",
            "description": "Difensore",
            "role": "D"
        },
        {
            "id": "1",
            "description": "Portiere",
            "role": "P"
        }
    ];

const allTeams =

    [
        {
            "id": "13",
            "matches": "2635",
            "name": "Cagliari",
            "stadium": "The Unipol Domus",
            "photo": "1472407936_cagliari.png",
            "photostadium": "1602833204_SardegnaArena.jpeg"
        },
        {
            "id": "39",
            "matches": "80",
            "name": "Inter",
            "stadium": "San Siro",
            "photo": "1221989092_logo_inter.jpg",
            "photostadium": "1602834399_sansiro-inter.jpg"
        },
        {
            "id": "74",
            "matches": "80",
            "name": "Roma",
            "stadium": "Olimpico",
            "photo": "1228064826_Logo Roma.jpg",
            "photostadium": "1602834481_olimpico-roma.jpeg"
        },
        {
            "id": "31",
            "matches": "79",
            "name": "Fiorentina",
            "stadium": "A. Franchi",
            "photo": "1222015126_logo_fiorentina.jpg",
            "photostadium": "1602793848_franchi-stadio.jpg"
        },
        {
            "id": "77",
            "matches": "78",
            "name": "Sampdoria",
            "stadium": "Marassi",
            "photo": "1227457528_Logo Sampdoria.jpg",
            "photostadium": "1602834609_marassi-sampdoria.jpg"
        },
        {
            "id": "41",
            "matches": "78",
            "name": "Juventus",
            "stadium": "Juventus Stadium",
            "photo": "1220698373_logo_juventus.jpg",
            "photostadium": "1602834704_juventus-stadium.jpg"
        },
        {
            "id": "85",
            "matches": "77",
            "name": "Torino",
            "stadium": "Stadio Comunale",
            "photo": "1223238628_torinoax2.jpg",
            "photostadium": "1602834763_OlimpicoGrande_Torino.jpg"
        },
        {
            "id": "51",
            "matches": "76",
            "name": "Milan",
            "stadium": "San Siro",
            "photo": "1221989104_Logo-milan.gif",
            "photostadium": "1602834880_milan-sansiro.jpeg"
        },
        {
            "id": "55",
            "matches": "75",
            "name": "Napoli",
            "stadium": "San Paolo",
            "photo": "1226851539_logoo_napoli.jpeg",
            "photostadium": "1602834965_san-paolo-1.jpg"
        },
        {
            "id": "42",
            "matches": "75",
            "name": "Lazio",
            "stadium": "Olimpico",
            "photo": "1232294369_logo_lazio.jpg",
            "photostadium": "1602834888_olimpico-lazio.jpeg"
        },
        {
            "id": "10",
            "matches": "66",
            "name": "Bologna",
            "stadium": "Dall'Ara",
            "photo": "1225317389_100px-BolognaFootballClubLogo.png",
            "photostadium": "1602794622_bologna-stadio.jpg"
        },
        {
            "id": "60",
            "matches": "64",
            "name": "Parma",
            "stadium": "Tardini",
            "photo": "1253739069_logo_parma_calcio.gif",
            "photostadium": ""
        },
        {
            "id": "36",
            "matches": "62",
            "name": "Genoa",
            "stadium": "Ferraris",
            "photo": "1225039407_logo genoa x inter.jpg",
            "photostadium": ""
        },
        {
            "id": "6",
            "matches": "62",
            "name": "Atalanta",
            "stadium": "Atl. Azz.d'Italia",
            "photo": "1222015077_logo_atalanta.jpg",
            "photostadium": "1602794763_atalanta-stadio.jpg"
        },
        {
            "id": "89",
            "matches": "58",
            "name": "Udinese",
            "stadium": "Stadio Friuli",
            "photo": "1231697421_udinese.gif",
            "photostadium": ""
        },
        {
            "id": "59",
            "matches": "56",
            "name": "Palermo",
            "stadium": "R.Barbera",
            "photo": "1228064996_palermo_logo.jpg",
            "photostadium": ""
        },
        {
            "id": "11",
            "matches": "56",
            "name": "Brescia",
            "stadium": "Rigamonti",
            "photo": "1448317539_brescia.png",
            "photostadium": ""
        },
        {
            "id": "92",
            "matches": "54",
            "name": "Verona",
            "stadium": "Bentegodi",
            "photo": "1383169840_Hellas-Verona.png",
            "photostadium": ""
        },
        {
            "id": "19",
            "matches": "52",
            "name": "Catania",
            "stadium": "Stadio Massimino",
            "photo": "1225644816_logo_catania.jpg",
            "photostadium": ""
        },
        {
            "id": "94",
            "matches": "48",
            "name": "Vicenza",
            "stadium": "Romeo Menti",
            "photo": "1446017567_vicenza.png",
            "photostadium": ""
        },
        {
            "id": "8",
            "matches": "42",
            "name": "Bari",
            "stadium": "San Nicola",
            "photo": "1253463841_Logo_bari_new_184.jpg",
            "photostadium": ""
        },
        {
            "id": "25",
            "matches": "40",
            "name": "Como",
            "stadium": "Giuseppe Sinigaglia",
            "photo": "1448789154_como.jpeg",
            "photostadium": ""
        },
        {
            "id": "54",
            "matches": "37",
            "name": "Monza",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "57",
            "matches": "36",
            "name": "Novara",
            "stadium": "Silvio Piola",
            "photo": "1444601767_novara-logo.png",
            "photostadium": ""
        },
        {
            "id": "50",
            "matches": "36",
            "name": "Messina",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "22",
            "matches": "36",
            "name": "Cesena",
            "stadium": "Dino Manuzzi",
            "photo": "1420562032_cesena.png",
            "photostadium": ""
        },
        {
            "id": "61",
            "matches": "34",
            "name": "Perugia",
            "stadium": "Renato Curi",
            "photo": "1445780513_perugia.png",
            "photostadium": ""
        },
        {
            "id": "78",
            "matches": "34",
            "name": "Siena",
            "stadium": "A.Franchi",
            "photo": "1220694939_siena.jpg",
            "photostadium": ""
        },
        {
            "id": "5",
            "matches": "34",
            "name": "Ascoli",
            "stadium": "Cino e Lillo Del Duca",
            "photo": "1447594389_ascoli.png",
            "photostadium": ""
        },
        {
            "id": "62",
            "matches": "33",
            "name": "Pescara",
            "stadium": "Adriatico",
            "photo": "1360572977_pescara_logo.jpeg",
            "photostadium": ""
        },
        {
            "id": "76",
            "matches": "32",
            "name": "Sambenedettese",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "79",
            "matches": "32",
            "name": "Spal",
            "stadium": "Paolo Mazza",
            "photo": "1505060038_spal.png",
            "photostadium": ""
        },
        {
            "id": "32",
            "matches": "32",
            "name": "Foggia",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "43",
            "matches": "32",
            "name": "Lecce",
            "stadium": "Via del Mare",
            "photo": "1221571234_logo_lecce_big_miniatura.gif",
            "photostadium": ""
        },
        {
            "id": "90",
            "matches": "30",
            "name": "Varese",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "23",
            "matches": "30",
            "name": "Chievo",
            "stadium": "Bentegodi",
            "photo": "1224416386_logoCHVback.gif",
            "photostadium": ""
        },
        {
            "id": "29",
            "matches": "30",
            "name": "Empoli",
            "stadium": "Castellani",
            "photo": "1222015155_logo_empoli.jpg",
            "photostadium": ""
        },
        {
            "id": "75",
            "matches": "26",
            "name": "Salernitana",
            "stadium": "Arechi",
            "photo": "1450634368_salernitana.jpeg",
            "photostadium": ""
        },
        {
            "id": "52",
            "matches": "25",
            "name": "Modena",
            "stadium": "Alberto Braglia",
            "photo": "1446499768_modena.jpg",
            "photostadium": ""
        },
        {
            "id": "46",
            "matches": "24",
            "name": "Livorno",
            "stadium": "A. Picchi",
            "photo": "1248884769_livorno.gif",
            "photostadium": ""
        },
        {
            "id": "91",
            "matches": "24",
            "name": "Venezia",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "83",
            "matches": "24",
            "name": "Ternana",
            "stadium": "Libero Liberati",
            "photo": "1441657946_logo_ternana.gif",
            "photostadium": ""
        },
        {
            "id": "20",
            "matches": "24",
            "name": "Catanzaro",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "65",
            "matches": "22",
            "name": "Pistoiese",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "81",
            "matches": "22",
            "name": "Taranto",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "58",
            "matches": "22",
            "name": "Padova",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "28",
            "matches": "22",
            "name": "Crotone",
            "stadium": "Ezio Scida",
            "photo": "1440574847_crotone.png",
            "photostadium": "1602833258_stadio-ezio-scida-653x367.jpg"
        },
        {
            "id": "88",
            "matches": "21",
            "name": "Triestina",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "4",
            "matches": "20",
            "name": "Arezzo",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "72",
            "matches": "20",
            "name": "Reggina",
            "stadium": "Granillo",
            "photo": "1229291172_LogoRegginaCalcio.jpg",
            "photostadium": ""
        },
        {
            "id": "7",
            "matches": "18",
            "name": "Avellino",
            "stadium": "Partenio-Adriano Lombardi",
            "photo": "1442072874_avellino.jpeg",
            "photostadium": ""
        },
        {
            "id": "63",
            "matches": "17",
            "name": "Piacenza",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "49",
            "matches": "16",
            "name": "Marzotto",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "27",
            "matches": "16",
            "name": "Cremonese",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "3",
            "matches": "16",
            "name": "Ancona",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "64",
            "matches": "16",
            "name": "Pisa",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "71",
            "matches": "14",
            "name": "Reggiana",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "26",
            "matches": "14",
            "name": "Cosenza",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "87",
            "matches": "14",
            "name": "Treviso",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "97",
            "matches": "13",
            "name": "Sassuolo",
            "stadium": "Mapei Stadium - Citt\u00e0 del Tricolore",
            "photo": "1385416604_sassuolo.jpeg",
            "photostadium": ""
        },
        {
            "id": "15",
            "matches": "12",
            "name": "Campobasso",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "73",
            "matches": "12",
            "name": "Rimini",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "44",
            "matches": "12",
            "name": "Lecco",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "68",
            "matches": "12",
            "name": "Prato",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "2",
            "matches": "12",
            "name": "Alessandria",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "48",
            "matches": "10",
            "name": "Mantova",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "80",
            "matches": "10",
            "name": "Spezia",
            "stadium": "Alberto Picco",
            "photo": "1446968288_spezoa.png",
            "photostadium": ""
        },
        {
            "id": "93",
            "matches": "8",
            "name": "Viareggio",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "70",
            "matches": "8",
            "name": "Ravenna",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "86",
            "matches": "8",
            "name": "Torres",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "47",
            "matches": "8",
            "name": "Lucchese",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "69",
            "matches": "8",
            "name": "ProPatria",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "38",
            "matches": "8",
            "name": "Grosseto",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "100",
            "matches": "6",
            "name": "Latina",
            "stadium": "Domenico Francioni",
            "photo": "1442958579_us_latina.png",
            "photostadium": ""
        },
        {
            "id": "103",
            "matches": "6",
            "name": "ProVercelli",
            "stadium": "Silvio Piola",
            "photo": "1451045101_fc_pro_vercelli.png",
            "photostadium": ""
        },
        {
            "id": "35",
            "matches": "6",
            "name": "Frosinone",
            "stadium": "Benito Stirpe",
            "photo": "1543257498_frosinone-128x128.png",
            "photostadium": ""
        },
        {
            "id": "33",
            "matches": "4",
            "name": "Forli'",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "24",
            "matches": "4",
            "name": "Cittadella",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "53",
            "matches": "4",
            "name": "Monopoli",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "12",
            "matches": "4",
            "name": "Brindisi",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "95",
            "matches": "4",
            "name": "Vis Pesaro",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "17",
            "matches": "4",
            "name": "Casertana",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "40",
            "matches": "4",
            "name": "Ischia",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "34",
            "matches": "4",
            "name": "Francavilla",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "45",
            "matches": "4",
            "name": "Licata",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "56",
            "matches": "4",
            "name": "Nocerina",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "9",
            "matches": "2",
            "name": "Barletta",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "84",
            "matches": "2",
            "name": "Tevere Roma",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "37",
            "matches": "2",
            "name": "Giarre",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "1",
            "matches": "2",
            "name": "Albinoleffe",
            "stadium": "Citta di Gorgonzola",
            "photo": "1602793563_logoalbinoleffe.png",
            "photostadium": "1602793563_albinoleffestadio.jpg"
        },
        {
            "id": "16",
            "matches": "2",
            "name": "Casarano",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "21",
            "matches": "2",
            "name": "Cavese",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "18",
            "matches": "2",
            "name": "Castel di Sangro",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "99",
            "matches": "2",
            "name": "VirtusEntella",
            "stadium": "Comunale di Chiavari",
            "photo": "1442696381_entella.png",
            "photostadium": ""
        },
        {
            "id": "30",
            "matches": "2",
            "name": "Fidelis Andria",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "101",
            "matches": "2",
            "name": "Trapani",
            "stadium": "Polisportivo Provinciale",
            "photo": "1445182368_trapani.png",
            "photostadium": ""
        },
        {
            "id": "102",
            "matches": "2",
            "name": "Lanciano",
            "stadium": "Guido Biondi",
            "photo": "1449479676_lanciano.jpeg",
            "photostadium": ""
        },
        {
            "id": "105",
            "matches": "2",
            "name": "Benevento",
            "stadium": "Vigorito",
            "photo": "1508744428_1463671391_benevento-calcio.png",
            "photostadium": ""
        },
        {
            "id": "67",
            "matches": "2",
            "name": "Potenza",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "14",
            "matches": "2",
            "name": "Campania",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "82",
            "matches": "2",
            "name": "Teramo",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        },
        {
            "id": "66",
            "matches": "1",
            "name": "Portocivitanova",
            "stadium": "",
            "photo": "",
            "photostadium": ""
        }
    ];

const allCountries =

    [
        {
            "id": "44",
            "description": "Zambia",
            "flag": "zambia.png"
        },
        {
            "id": "43",
            "description": "Uzbekistan",
            "flag": "uzbekistan.png"
        },
        {
            "id": "42",
            "description": "Repubblica del Congo",
            "flag": "repcongo.png"
        },
        {
            "id": "41",
            "description": "Slovacchia",
            "flag": "slovacchia.png"
        },
        {
            "id": "40",
            "description": "Senegal",
            "flag": "senegal.png"
        },
        {
            "id": "39",
            "description": "Angola",
            "flag": "angola.png"
        },
        {
            "id": "38",
            "description": "Romania",
            "flag": "romania.png"
        },
        {
            "id": "37",
            "description": "Bulgaria",
            "flag": "bulgaria.jpg"
        },
        {
            "id": "36",
            "description": "Estonia",
            "flag": "estonia.png"
        },
        {
            "id": "35",
            "description": "Olanda",
            "flag": "olanda.gif"
        },
        {
            "id": "34",
            "description": "Corea Del Nord",
            "flag": "koreanord.png"
        },
        {
            "id": "33",
            "description": "Moldavia",
            "flag": "moldavia.png"
        },
        {
            "id": "32",
            "description": "Polonia",
            "flag": "polonia.png"
        },
        {
            "id": "31",
            "description": "Ghana",
            "flag": "ghana.gif"
        },
        {
            "id": "30",
            "description": "Repubblica Ceca",
            "flag": "rep_ceca.png"
        },
        {
            "id": "29",
            "description": "Macedonia",
            "flag": "macedonia.png"
        },
        {
            "id": "28",
            "description": "Cile",
            "flag": "cile.png"
        },
        {
            "id": "27",
            "description": "Svezia",
            "flag": "svezia.png"
        },
        {
            "id": "26",
            "description": "Colombia",
            "flag": "colombia.png"
        },
        {
            "id": "25",
            "description": "Serbia",
            "flag": "serbia.png"
        },
        {
            "id": "24",
            "description": "Marocco",
            "flag": "marocco.png"
        },
        {
            "id": "23",
            "description": "Croazia",
            "flag": "croazia.gif"
        },
        {
            "id": "22",
            "description": "Ungheria",
            "flag": "ungheria.jpg"
        },
        {
            "id": "21",
            "description": "Bielorussia",
            "flag": "Bielorussia.png"
        },
        {
            "id": "20",
            "description": "Venezuela",
            "flag": "venezuela.gif"
        },
        {
            "id": "19",
            "description": "Slovenia",
            "flag": "slovenia.jpg"
        },
        {
            "id": "18",
            "description": "Portogallo",
            "flag": "portogallo.png"
        },
        {
            "id": "17",
            "description": "Inghilterra",
            "flag": "gb.png"
        },
        {
            "id": "16",
            "description": "Spagna",
            "flag": "es.png"
        },
        {
            "id": "15",
            "description": "Peru",
            "flag": "pe.png"
        },
        {
            "id": "14",
            "description": "Panama",
            "flag": "panama.png"
        },
        {
            "id": "13",
            "description": "Danimarca",
            "flag": "dk.png"
        },
        {
            "id": "12",
            "description": "Sierra Leone",
            "flag": "sierra_leone.png"
        },
        {
            "id": "11",
            "description": "Sud Africa",
            "flag": "sud_africa.png"
        },
        {
            "id": "10",
            "description": "Belgio",
            "flag": "be.png"
        },
        {
            "id": "9",
            "description": "Camerun",
            "flag": "cameroon.png"
        },
        {
            "id": "8",
            "description": "Argentina",
            "flag": "ar.png"
        },
        {
            "id": "7",
            "description": "Grecia",
            "flag": "gr.png"
        },
        {
            "id": "6",
            "description": "Svizzera",
            "flag": "ch.png"
        },
        {
            "id": "5",
            "description": "Brasile",
            "flag": "br.png"
        },
        {
            "id": "4",
            "description": "Francia",
            "flag": "fr.png"
        },
        {
            "id": "3",
            "description": "Honduras",
            "flag": "honduras.png"
        },
        {
            "id": "2",
            "description": "Uruguay",
            "flag": "uruguay.png"
        },
        {
            "id": "1",
            "description": "Italia",
            "flag": "it.png"
        }
    ]
    ;

const allSeasons =

    [
        {
            "id": "82",
            "year": "2024/2025",
            "league": "A",
            "position": "0",
            "points": "0",
            "v": "0",
            "d": "0",
            "l": "0",
            "gf": "0",
            "gs": "0",
            "dr": "0"
        },
        {
            "id": "81",
            "year": "2023/2024",
            "league": "A",
            "position": "16",
            "points": "36",
            "v": "8",
            "d": "12",
            "l": "18",
            "gf": "42",
            "gs": "68",
            "dr": "-26"
        },        
        {
            "id": "80",
            "year": "2022/2023",
            "league": "B",
            "position": "5",
            "points": "60",
            "v": "15",
            "d": "15",
            "l": "8",
            "gf": "50",
            "gs": "34",
            "dr": "16"
        },
        {
            "id": "79",
            "year": "2021/2022",
            "league": "A",
            "position": "18",
            "points": "30",
            "v": "6",
            "d": "12",
            "l": "20",
            "gf": "34",
            "gs": "68",
            "dr": " -34"
        },
        {
            "id": "77",
            "year": "2020/2021",
            "league": "A",
            "position": "16",
            "points": "37",
            "v": "9",
            "d": "10",
            "l": "19",
            "gf": "43",
            "gs": "59",
            "dr": "-16"
        },
        {
            "id": "76",
            "year": "2019/2020",
            "league": "A",
            "position": "14",
            "points": "45",
            "v": "11",
            "d": "12",
            "l": "15",
            "gf": "52",
            "gs": "56",
            "dr": "-4"
        },
        {
            "id": "75",
            "year": "2018/2019",
            "league": "A",
            "position": "15",
            "points": "40",
            "v": "10",
            "d": "11",
            "l": "16",
            "gf": "35",
            "gs": "53",
            "dr": "-18"
        },
        {
            "id": "74",
            "year": "2017/2018",
            "league": "A",
            "position": "16",
            "points": "39",
            "v": "11",
            "d": "6",
            "l": "21",
            "gf": "33",
            "gs": "61",
            "dr": "-28"
        },
        {
            "id": "73",
            "year": "2016/2017",
            "league": "A",
            "position": "11",
            "points": "47",
            "v": "14",
            "d": "5",
            "l": "18",
            "gf": "55",
            "gs": "77",
            "dr": "-22"
        },
        {
            "id": "72",
            "year": "2015/2016",
            "league": "B",
            "position": "1",
            "points": "83",
            "v": "25",
            "d": "8",
            "l": "9",
            "gf": "78",
            "gs": "41",
            "dr": "37"
        },
        {
            "id": "71",
            "year": "2014/2015",
            "league": "A",
            "position": "0",
            "points": "34",
            "v": "8",
            "d": "10",
            "l": "20",
            "gf": "48",
            "gs": "68",
            "dr": "-20"
        },
        {
            "id": "70",
            "year": "2013/2014",
            "league": "A",
            "position": "15",
            "points": "39",
            "v": "9",
            "d": "12",
            "l": "17",
            "gf": "34",
            "gs": "53",
            "dr": "-19"
        },
        {
            "id": "69",
            "year": "2012/2013",
            "league": "A",
            "position": "11",
            "points": "47",
            "v": "11",
            "d": "11",
            "l": "15",
            "gf": "42",
            "gs": "52",
            "dr": "-10"
        },
        {
            "id": "65",
            "year": "2011/2012",
            "league": "A",
            "position": "0",
            "points": "43",
            "v": "10",
            "d": "13",
            "l": "15",
            "gf": "37",
            "gs": "46",
            "dr": "-9"
        },
        {
            "id": "64",
            "year": "2010/2011",
            "league": "A",
            "position": "0",
            "points": "45",
            "v": "11",
            "d": "9",
            "l": "17",
            "gf": "44",
            "gs": "51",
            "dr": "-7"
        },
        {
            "id": "59",
            "year": "2009/2010",
            "league": "A",
            "position": "13",
            "points": "44",
            "v": "11",
            "d": "11",
            "l": "16",
            "gf": "56",
            "gs": "58",
            "dr": "-2"
        },
        {
            "id": "51",
            "year": "2008/2009",
            "league": "A",
            "position": "9",
            "points": "53",
            "v": "15",
            "d": "8",
            "l": "15",
            "gf": "49",
            "gs": "50",
            "dr": "-1"
        },
        {
            "id": "45",
            "year": "2007/2008",
            "league": "A",
            "position": "14",
            "points": "42",
            "v": "11",
            "d": "9",
            "l": "18",
            "gf": "39",
            "gs": "55",
            "dr": "-16"
        },
        {
            "id": "42",
            "year": "2006/2007",
            "league": "A",
            "position": "14",
            "points": "37",
            "v": "9",
            "d": "13",
            "l": "16",
            "gf": "35",
            "gs": "46",
            "dr": "-12"
        },
        {
            "id": "5",
            "year": "2005/2006",
            "league": "A",
            "position": "16",
            "points": "38",
            "v": "8",
            "d": "15",
            "l": "15",
            "gf": "42",
            "gs": "55",
            "dr": "-13"
        },
        {
            "id": "1",
            "year": "2004/2005",
            "league": "A",
            "position": "12",
            "points": "44",
            "v": "10",
            "d": "14",
            "l": "14",
            "gf": "51",
            "gs": "60",
            "dr": "-9"
        },
        {
            "id": "2",
            "year": "2003/2004",
            "league": "B",
            "position": "1",
            "points": "83",
            "v": "23",
            "d": "14",
            "l": "9",
            "gf": "80",
            "gs": "51",
            "dr": "29"
        },
        {
            "id": "3",
            "year": "2002/2003",
            "league": "B",
            "position": "8",
            "points": "50",
            "v": "14",
            "d": "12",
            "l": "12",
            "gf": "47",
            "gs": "46",
            "dr": "1"
        },
        {
            "id": "4",
            "year": "2001/2002",
            "league": "B",
            "position": "0",
            "points": "45",
            "v": "9",
            "d": "18",
            "l": "11",
            "gf": "36",
            "gs": "39",
            "dr": "-3"
        },
        {
            "id": "6",
            "year": "2000/2001",
            "league": "B",
            "position": "0",
            "points": "47",
            "v": "11",
            "d": "14",
            "l": "13",
            "gf": "51",
            "gs": "42",
            "dr": "9"
        },
        {
            "id": "7",
            "year": "1999/2000",
            "league": "A",
            "position": "0",
            "points": "21",
            "v": "3",
            "d": "13",
            "l": "18",
            "gf": "29",
            "gs": "53",
            "dr": "-24"
        },
        {
            "id": "8",
            "year": "1998/1999",
            "league": "A",
            "position": "12",
            "points": "41",
            "v": "11",
            "d": "8",
            "l": "15",
            "gf": "49",
            "gs": "50",
            "dr": "-1"
        },
        {
            "id": "9",
            "year": "1997/1998",
            "league": "B",
            "position": "3",
            "points": "63",
            "v": "15",
            "d": "18",
            "l": "5",
            "gf": "53",
            "gs": "36",
            "dr": "17"
        },
        {
            "id": "10",
            "year": "1996/1997",
            "league": "A",
            "position": "15",
            "points": "37",
            "v": "9",
            "d": "10",
            "l": "16",
            "gf": "46",
            "gs": "58",
            "dr": "-13"
        },
        {
            "id": "11",
            "year": "1995/1996",
            "league": "A",
            "position": "11",
            "points": "41",
            "v": "11",
            "d": "8",
            "l": "15",
            "gf": "34",
            "gs": "47",
            "dr": "-13"
        },
        {
            "id": "12",
            "year": "1994/1995",
            "league": "A",
            "position": "9",
            "points": "49",
            "v": "13",
            "d": "10",
            "l": "11",
            "gf": "40",
            "gs": "39",
            "dr": "-1"
        },
        {
            "id": "13",
            "year": "1993/1994",
            "league": "A",
            "position": "12",
            "points": "32",
            "v": "10",
            "d": "12",
            "l": "12",
            "gf": "39",
            "gs": "48",
            "dr": "-9"
        },
        {
            "id": "14",
            "year": "1992/1993",
            "league": "A",
            "position": "6",
            "points": "37",
            "v": "14",
            "d": "9",
            "l": "11",
            "gf": "45",
            "gs": "33",
            "dr": "12"
        },
        {
            "id": "15",
            "year": "1991/1992",
            "league": "A",
            "position": "13",
            "points": "29",
            "v": "7",
            "d": "15",
            "l": "12",
            "gf": "30",
            "gs": "34",
            "dr": "-4"
        },
        {
            "id": "16",
            "year": "1990/1991",
            "league": "A",
            "position": "14",
            "points": "29",
            "v": "6",
            "d": "17",
            "l": "11",
            "gf": "29",
            "gs": "44",
            "dr": "-15"
        },
        {
            "id": "17",
            "year": "1989/1990",
            "league": "B",
            "position": "3",
            "points": "47",
            "v": "17",
            "d": "13",
            "l": "8",
            "gf": "39",
            "gs": "22",
            "dr": "17"
        },
        {
            "id": "18",
            "year": "1988/1989",
            "league": "C1",
            "position": "1",
            "points": "45",
            "v": "16",
            "d": "13",
            "l": "5",
            "gf": "37",
            "gs": "21",
            "dr": "16"
        },
        {
            "id": "19",
            "year": "1987/1988",
            "league": "C1",
            "position": "11",
            "points": "31",
            "v": "11",
            "d": "9",
            "l": "14",
            "gf": "34",
            "gs": "33",
            "dr": "-1"
        },
        {
            "id": "20",
            "year": "1986/1987",
            "league": "B",
            "position": "20",
            "points": "26",
            "v": "9",
            "d": "13",
            "l": "16",
            "gf": "32",
            "gs": "47",
            "dr": "-15"
        },
        {
            "id": "21",
            "year": "1985/1986",
            "league": "B",
            "position": "15",
            "points": "36",
            "v": "13",
            "d": "10",
            "l": "15",
            "gf": "27",
            "gs": "38",
            "dr": "-11"
        },
        {
            "id": "22",
            "year": "1984/1985",
            "league": "B",
            "position": "17",
            "points": "34",
            "v": "12",
            "d": "10",
            "l": "16",
            "gf": "29",
            "gs": "32",
            "dr": "-3"
        },
        {
            "id": "23",
            "year": "1983/1984",
            "league": "B",
            "position": "11",
            "points": "36",
            "v": "10",
            "d": "16",
            "l": "12",
            "gf": "31",
            "gs": "32",
            "dr": "-1"
        },
        {
            "id": "24",
            "year": "1982/1983",
            "league": "A",
            "position": "14",
            "points": "26",
            "v": "6",
            "d": "14",
            "l": "10",
            "gf": "23",
            "gs": "33",
            "dr": "-10"
        },
        {
            "id": "25",
            "year": "1981/1982",
            "league": "A",
            "position": "12",
            "points": "25",
            "v": "7",
            "d": "11",
            "l": "12",
            "gf": "33",
            "gs": "36",
            "dr": "-3"
        },
        {
            "id": "26",
            "year": "1980/1981",
            "league": "A",
            "position": "6",
            "points": "30",
            "v": "8",
            "d": "14",
            "l": "8",
            "gf": "29",
            "gs": "30",
            "dr": "-1"
        },
        {
            "id": "27",
            "year": "1979/1980",
            "league": "A",
            "position": "9",
            "points": "30",
            "v": "8",
            "d": "14",
            "l": "8",
            "gf": "27",
            "gs": "29",
            "dr": "-2"
        },
        {
            "id": "28",
            "year": "1978/1979",
            "league": "B",
            "position": "2",
            "points": "49",
            "v": "16",
            "d": "17",
            "l": "5",
            "gf": "46",
            "gs": "24",
            "dr": "22"
        },
        {
            "id": "29",
            "year": "1977/1978",
            "league": "B",
            "position": "12",
            "points": "37",
            "v": "12",
            "d": "13",
            "l": "13",
            "gf": "52",
            "gs": "47",
            "dr": "5"
        },
        {
            "id": "30",
            "year": "1976/1977",
            "league": "B",
            "position": "4",
            "points": "49",
            "v": "17",
            "d": "15",
            "l": "6",
            "gf": "45",
            "gs": "32",
            "dr": "13"
        },
        {
            "id": "31",
            "year": "1975/1976",
            "league": "A",
            "position": "16",
            "points": "19",
            "v": "5",
            "d": "9",
            "l": "16",
            "gf": "25",
            "gs": "52",
            "dr": "-27"
        },
        {
            "id": "32",
            "year": "1974/1975",
            "league": "A",
            "position": "10",
            "points": "26",
            "v": "6",
            "d": "14",
            "l": "10",
            "gf": "22",
            "gs": "30",
            "dr": "-12"
        },
        {
            "id": "33",
            "year": "1973/1974",
            "league": "A",
            "position": "10",
            "points": "28",
            "v": "7",
            "d": "14",
            "l": "9",
            "gf": "25",
            "gs": "32",
            "dr": "-7"
        },
        {
            "id": "34",
            "year": "1972/1973",
            "league": "A",
            "position": "8",
            "points": "29",
            "v": "9",
            "d": "11",
            "l": "10",
            "gf": "26",
            "gs": "28",
            "dr": "-2"
        },
        {
            "id": "35",
            "year": "1971/1972",
            "league": "A",
            "position": "4",
            "points": "39",
            "v": "15",
            "d": "9",
            "l": "6",
            "gf": "39",
            "gs": "23",
            "dr": "16"
        },
        {
            "id": "36",
            "year": "1970/1971",
            "league": "A",
            "position": "7",
            "points": "30",
            "v": "8",
            "d": "14",
            "l": "8",
            "gf": "33",
            "gs": "35",
            "dr": "-2"
        },
        {
            "id": "37",
            "year": "1969/1970",
            "league": "A",
            "position": "1",
            "points": "45",
            "v": "17",
            "d": "11",
            "l": "2",
            "gf": "42",
            "gs": "11",
            "dr": "31"
        },
        {
            "id": "38",
            "year": "1968/1969",
            "league": "A",
            "position": "2",
            "points": "41",
            "v": "14",
            "d": "13",
            "l": "3",
            "gf": "41",
            "gs": "18",
            "dr": "23"
        },
        {
            "id": "39",
            "year": "1967/1968",
            "league": "A",
            "position": "9",
            "points": "31",
            "v": "12",
            "d": "7",
            "l": "11",
            "gf": "44",
            "gs": "38",
            "dr": "6"
        },
        {
            "id": "41",
            "year": "1966/1967",
            "league": "A",
            "position": "6",
            "points": "40",
            "v": "13",
            "d": "14",
            "l": "7",
            "gf": "35",
            "gs": "17",
            "dr": "18"
        },
        {
            "id": "43",
            "year": "1965/1966",
            "league": "A",
            "position": "11",
            "points": "30",
            "v": "10",
            "d": "10",
            "l": "14",
            "gf": "36",
            "gs": "37",
            "dr": "-1"
        },
        {
            "id": "44",
            "year": "1964/1965",
            "league": "A",
            "position": "7",
            "points": "34",
            "v": "13",
            "d": "8",
            "l": "13",
            "gf": "33",
            "gs": "35",
            "dr": "-2"
        },
        {
            "id": "46",
            "year": "1963/1964",
            "league": "B",
            "position": "2",
            "points": "49",
            "v": "16",
            "d": "17",
            "l": "5",
            "gf": "44",
            "gs": "23",
            "dr": "-21"
        },
        {
            "id": "47",
            "year": "1962/1963",
            "league": "B",
            "position": "9",
            "points": "38",
            "v": "12",
            "d": "14",
            "l": "12",
            "gf": "41",
            "gs": "40",
            "dr": "1"
        },
        {
            "id": "48",
            "year": "1961/1962",
            "league": "C1",
            "position": "1",
            "points": "44",
            "v": "17",
            "d": "10",
            "l": "7",
            "gf": "51",
            "gs": "23",
            "dr": "28"
        },
        {
            "id": "49",
            "year": "1960/1961",
            "league": "C1",
            "position": "2",
            "points": "44",
            "v": "18",
            "d": "8",
            "l": "8",
            "gf": "48",
            "gs": "28",
            "dr": "20"
        },
        {
            "id": "50",
            "year": "1959/1960",
            "league": "B",
            "position": "20",
            "points": "31",
            "v": "8",
            "d": "15",
            "l": "15",
            "gf": "36",
            "gs": "54",
            "dr": "-18"
        },
        {
            "id": "52",
            "year": "1958/1959",
            "league": "B",
            "position": "5",
            "points": "43",
            "v": "15",
            "d": "13",
            "l": "10",
            "gf": "48",
            "gs": "48",
            "dr": "0"
        },
        {
            "id": "53",
            "year": "1957/1958",
            "league": "B",
            "position": "17",
            "points": "26",
            "v": "8",
            "d": "10",
            "l": "16",
            "gf": "31",
            "gs": "44",
            "dr": "-13"
        },
        {
            "id": "54",
            "year": "1956/1957",
            "league": "B",
            "position": "10",
            "points": "32",
            "v": "11",
            "d": "10",
            "l": "13",
            "gf": "30",
            "gs": "34",
            "dr": "-4"
        },
        {
            "id": "55",
            "year": "1955/1956",
            "league": "B",
            "position": "6",
            "points": "40",
            "v": "15",
            "d": "10",
            "l": "9",
            "gf": "51",
            "gs": "45",
            "dr": "6"
        },
        {
            "id": "56",
            "year": "1954/1955",
            "league": "B",
            "position": "9",
            "points": "33",
            "v": "10",
            "d": "13",
            "l": "11",
            "gf": "33",
            "gs": "35",
            "dr": "-2"
        },
        {
            "id": "57",
            "year": "1953/1954",
            "league": "B",
            "position": "2",
            "points": "41",
            "v": "16",
            "d": "9",
            "l": "9",
            "gf": "42",
            "gs": "30",
            "dr": "-12"
        },
        {
            "id": "58",
            "year": "1952/1953",
            "league": "B",
            "position": "6",
            "points": "38",
            "v": "14",
            "d": "10",
            "l": "10",
            "gf": "51",
            "gs": "44",
            "dr": "7"
        },
        {
            "id": "61",
            "year": "1951/1952",
            "league": "C1",
            "position": "1",
            "points": "52",
            "v": "26",
            "d": "10",
            "l": "4",
            "gf": "75",
            "gs": "24",
            "dr": "51"
        },
        {
            "id": "62",
            "year": "1950/1951",
            "league": "C1",
            "position": "5",
            "points": "43",
            "v": "15",
            "d": "13",
            "l": "10",
            "gf": "59",
            "gs": "45",
            "dr": "14"
        },
        {
            "id": "63",
            "year": "1949/1950",
            "league": "C1",
            "position": "6",
            "points": "45",
            "v": "19",
            "d": "7",
            "l": "14",
            "gf": "67",
            "gs": "56",
            "dr": "11"
        },
        {
            "id": "66",
            "year": "1948/1949",
            "league": "C1",
            "position": "15",
            "points": "36",
            "v": "13",
            "d": "10",
            "l": "17",
            "gf": "48",
            "gs": "60",
            "dr": "-12"
        },
        {
            "id": "67",
            "year": "1947/1948",
            "league": "B",
            "position": "18",
            "points": "18",
            "v": "7",
            "d": "4",
            "l": "23",
            "gf": "32",
            "gs": "79",
            "dr": "-47"
        },
        {
            "id": "78",
            "year": "1946/1947",
            "league": "1RS",
            "position": "3",
            "points": "45",
            "v": "20",
            "d": "5",
            "l": "5",
            "gf": "74",
            "gs": "32",
            "dr": "42"
        }
    ];

const thisSeason = 81;

export { allCountries, allRoles, allTeams, allSeasons, thisSeason }
