import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: 440,
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  header: {
    fontWeight: 'bold'
  }
})
);

export default function Referees({ referees, seasonID, seasonLabel }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            Arbitri Stagione {seasonLabel}
          </Typography>
        </Toolbar>
      </AppBar>
      <Paper className={classes.root}>
        <TableContainer className={classes.container} size="small" aria-label="a dense table">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell className={classes.header} align='left'>Allenatore</StyledTableCell>
                <StyledTableCell className={classes.header} align='center'>Partite</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {referees.length > 0 ? (
                referees.map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell component="th" scope="row" align='left'>
                      <Typography variant="subtitle1">{row.surname} {row.name}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      <Typography variant="subtitle1" color="textSecondary" component="p">{row.appareances}</Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                  seasonID.length > 0 ? (
                    <StyledTableRow key='none'>
                      <StyledTableCell align="right" colSpan={7}>
                        <LinearProgress />
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : (
                      <StyledTableRow key='none'>
                        <StyledTableCell align="right" colSpan={7}>Seleziona stagione.</StyledTableCell>
                      </StyledTableRow>
                    ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
