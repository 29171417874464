import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import EventIcon from '@material-ui/icons/Event';
import Badge from '@material-ui/core/Badge';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import { allRoles, allCountries } from '../utils/generals'

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: 440,
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  header: {
    fontWeight: 'bold'
  },
  avatar: {
    backgroundColor: '#CA2331',
    color: '#FFFFFF',
  },
  avatarBlue: {
    backgroundColor: '#082D68',
    color: '#FFFFFF',
  },
  yellowShape: {
    backgroundColor: '#FDDB3A',
    width: 20,
    height: 30,
  },
  redShape: {
    backgroundColor: '#CA2331',
    width: 20,
    height: 30,
  }
})
);

export default function Players({ players, seasonID, seasonLabel, onPlayerSelect }) {
  const classes = useStyles();
  if (typeof players === 'undefined') {
    players = [];

  }
  const getFlag = (flag) => {
    // console.log('flag', flag);
    const country = allCountries.filter(country => country.description.toLowerCase() === flag.toLowerCase());
    return country[0].flag;
  }
  if (typeof players !== 'undefined' && players.length > 0) {
    players.map((player) => player.roleabbrevation = allRoles.filter((role) => role.description === player.role), [allRoles]);
  }
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            Rosa Stagione {seasonLabel}
          </Typography>
        </Toolbar>
      </AppBar>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <StyledTableCell className={classes.header} align='left'>Giocatore</StyledTableCell>
                <StyledTableCell className={classes.header} align='left'>Ruolo</StyledTableCell>
                <StyledTableCell className={classes.header} align='left'>Numero</StyledTableCell>
                <StyledTableCell className={classes.header} align='center'>Presenze</StyledTableCell>
                <StyledTableCell className={classes.header} align='center'>Gol</StyledTableCell>
                <StyledTableCell className={classes.header} align='center'>Ammonizioni</StyledTableCell>
                <StyledTableCell className={classes.header} align='center'>Espulsioni</StyledTableCell>
                <StyledTableCell className={classes.header} align='center'>Dettagli</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {players.length > 0 ? (
                players.map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell component="th" scope="row" align='left'>
                      <div className='place-avatar' style={{
                        backgroundImage: `url(https://www.archiviorossoblu.it/images/nazioni/${getFlag(row.country)})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundPositionX: 0,
                        margin: 0,
                        paddingLeft: 20,
                      }}>
                        <Typography variant="subtitle1">{row.surname} {row.name}</Typography>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align='left'>
                      <Avatar aria-label="recipe" className={classes.avatar}>{row.roleabbrevation[0].role}</Avatar>
                    </StyledTableCell>
                    <StyledTableCell align='left'>
                      <Avatar aria-label="recipe" className={classes.avatarBlue}>{row.number}</Avatar>
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      <Badge
                        badgeContent={row.appearances}
                        {...{
                          color: 'primary',
                          children: <EventIcon />,
                        }}
                        max={1000}
                      />
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      <Badge
                        badgeContent={row.goals}
                        {...{
                          color: 'primary',
                          children: <SportsSoccerIcon />,
                        }}
                        max={1000}
                      />
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      <Badge
                        color="primary"
                        badgeContent={row.yellowcard}
                        max={1000}
                      ><div className={classes.yellowShape} /></Badge>
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      <Badge
                        color="primary"
                        badgeContent={row.redcard}
                        max={1000}
                      ><div className={classes.redShape} /></Badge>
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      <IconButton aria-label="settings" onClick={() => onPlayerSelect(row.id)}>
                        <MoreVertIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                seasonID.length > 0 ? (
                  <StyledTableRow key='none'>
                    <StyledTableCell align="right" colSpan={8}>
                      <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
                      <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
                      <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
                      <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
                      <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  <StyledTableRow key='none'>
                    <StyledTableCell align="right" colSpan={7}>Seleziona stagione.</StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
