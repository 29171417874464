import axios from 'axios';

const api = process.env.REACT_APP_ATTR_API_URL || 'https://www.archiviorossoblu.it/api'

let token = localStorage.token

if (!token)
  token = localStorage.token = Math.random().toString(36).substr(-8)

const instance = axios.create({
  baseURL: api,
  timeout: 10000,
  headers: {
    'Accept': 'application/json',
    'Authorization': token,
  }
});


export const getAll = async (controller) => instance.get(`${controller}.php`)
  .then(res => res.data)
  .catch((error) => { console.log(error.response) });

export const getAllByID = async (controller, id) => instance.get(`${controller}.php?id=${id}`)
  .then(res => res.data)
  .catch((error) => { console.log(error.response) });
