import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import Footer from '../components/Footer';
import StatsDetails from '../components/StatsDetails'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop: 0,
        paddingTop: 0,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'justify',
        color: theme.palette.text.secondary,
    }
}));

export default function Stats() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Grid item xs={12}>
                <StatsDetails
                />
            </Grid>
            <Grid item xs={12}>
                <Footer />
            </Grid>
        </Box >
    )
}