import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { allCountries } from '../../utils/generals'
import Moment from 'react-moment';
import 'moment/locale/it';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import EventIcon from '@material-ui/icons/Event';
import Badge from '@material-ui/core/Badge';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 12,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    title: {
        backgroundColor: "#FFFFFF",
        color: "#082D68"
    },
    avatar: {
        backgroundColor: '#CA2331',
        color: '#FFFFFF',
    },
    avatarBlue: {
        backgroundColor: '#082D68',
        color: '#FFFFFF',
    },
    yellowShape: {
        backgroundColor: '#FDDB3A',
        width: 20,
        height: 30,
    },
    redShape: {
        backgroundColor: '#CA2331',
        width: 20,
        height: 30,
    },
    container: {
        maxHeight: 440,
    },
}));

export default function PlayerReport({ open, handleClose, playerId, playerReport }) {
    console.log("PlayerReport")
    console.log("playerId", playerId)
    console.log("open", open)
    const classes = useStyles();
    const descriptionElementRef = useRef(null);
    const getFlag = (flag) => {
        const country = allCountries.filter(country => country.description === flag);
        return country[0].flag;
    }

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open, playerId]);
    return (
        <div>
            { (playerId > 0) ? (
                <Dialog
                    open={open}
                    onClose={handleClose}
                    scroll="paper"
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogTitle id="scroll-dialog-title" className={classes.title}>
                        <div className='place-avatar' style={{
                            backgroundImage: `url(https://www.archiviorossoblu.it/images/nazioni/${getFlag(playerReport.data.country)})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundPositionX: 0,
                            margin: 0,
                            paddingLeft: 20,
                        }}>
                            {playerReport.data.name} {playerReport.data.surname}
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {playerReport.data.role} - {playerReport.data.country} - {(playerReport.data.date !== '0000-00-00') ? (<Moment locale='it' format="LL">{playerReport.data.date}</Moment>) : null}
                        </Typography>
                        <Paper className={classes.root}>
                            <TableContainer className={classes.container}>
                                <Table stickyHeader size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell className={classes.header} align='left'>Anno</StyledTableCell>
                                            <StyledTableCell className={classes.header} align='left'>Serie</StyledTableCell>
                                            <StyledTableCell className={classes.header} align='left'>Presenze</StyledTableCell>
                                            <StyledTableCell className={classes.header} align='left'>Goal</StyledTableCell>
                                            <StyledTableCell className={classes.header} align='center'>Ammonizioni</StyledTableCell>
                                            <StyledTableCell className={classes.header} align='center'>Espulsioni</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {playerReport.seasons.length > 0 ? (
                                            playerReport.seasons.map((row) => (
                                                <StyledTableRow key={row.year}>
                                                    <StyledTableCell align='left'>
                                                        {row.year}
                                                    </StyledTableCell>
                                                    <StyledTableCell align='left'>
                                                        <Avatar aria-label="recipe" className={classes.avatarBlue}>{row.league}</Avatar>
                                                    </StyledTableCell>
                                                    <StyledTableCell align='center'>
                                                        <Badge
                                                            badgeContent={row.appareances}
                                                            {...{
                                                                color: 'primary',
                                                                children: <EventIcon />,
                                                            }}
                                                            max={1000}
                                                        />
                                                    </StyledTableCell>
                                                    <StyledTableCell align='center'>
                                                        <Badge
                                                            badgeContent={row.goal}
                                                            {...{
                                                                color: 'primary',
                                                                children: <SportsSoccerIcon />,
                                                            }}
                                                            max={1000}
                                                        />
                                                    </StyledTableCell>
                                                    <StyledTableCell align='center'>
                                                        <Badge
                                                            color="primary"
                                                            badgeContent={row.yellowcard}
                                                            max={1000}
                                                        ><div className={classes.yellowShape} /></Badge>
                                                    </StyledTableCell>
                                                    <StyledTableCell align='center'>
                                                        <Badge
                                                            color="primary"
                                                            badgeContent={row.redcard}
                                                            max={1000}
                                                        ><div className={classes.redShape} /></Badge>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))) : null}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Chiudi
                    </Button>
                    </DialogActions>
                </Dialog>
            ) : null}
        </div>
    );
}