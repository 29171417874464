import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Moment from 'react-moment';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: 440,
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  header: {
    fontWeight: 'bold'
  },
  avatarBlue: {
    backgroundColor: '#082D68',
    color: '#FFFFFF',
  }
})
);

export default function Matches({ matches, seasonID, seasonLabel, onMatchSelect }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            Campionato Stagione {seasonLabel}
          </Typography>
        </Toolbar>
      </AppBar>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <StyledTableCell className={classes.header}>Giornata</StyledTableCell>
                <StyledTableCell className={classes.header}>Data</StyledTableCell>
                <StyledTableCell className={classes.header}>Partita</StyledTableCell>
                <StyledTableCell className={classes.header}>Dettagli</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {matches.length > 0 ? (
                matches.map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell>
                      <Avatar aria-label="recipe" className={classes.avatarBlue}>{row.day}</Avatar>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography variant="subtitle1" color="textSecondary" component="p">
                        <Moment locale='it' format="LLL">{row.date}</Moment>
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      {(row.played === 'Y') ? (
                        <Typography variant="subtitle1" color="textSecondary" component="p">
                          {row.home} {row.gh} - {row.ga} {row.away}
                        </Typography>
                      ) : (
                          <Typography variant="subtitle1" color="textSecondary" component="p">
                            {row.home} VS {row.away}
                          </Typography>
                        )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <IconButton aria-label="settings" onClick={() => onMatchSelect(row.id)}>
                        <MoreVertIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                  seasonID.length > 0 ? (
                    <StyledTableRow key='none'>
                      <StyledTableCell align="right" colSpan={4}>
                        <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
                        <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
                        <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
                        <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
                        <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : (
                      <StyledTableRow key='none'>
                        <StyledTableCell align="right" colSpan={4}>Seleziona stagione.</StyledTableCell>
                      </StyledTableRow>
                    ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
