import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Seasons, Who, Stats, Players, Matches, Privacy } from './pages';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { BrowserRouter, Route } from 'react-router-dom'

const theme = createTheme({
  palette: {
    primary: {
      main: '#082D68',
      contrastText: '#FFFFFF',
    },
    secondary: {
      // main: '#CA2331',
      main: '#CA2331',
    },
    red: '#CA2331',
  },
})
ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <BrowserRouter>
      <Route exact path="/" component={Seasons} />
      <Route exact path="/stagioni" component={Seasons} />
      <Route exact path="/statistiche" component={Stats} />
      <Route exact path="/giocatori" component={Players} />
      <Route exact path="/partite" component={Matches} />
      <Route exact path="/chisiamo" component={Who} />
      <Route exact path="/privacy" component={Privacy} />
    </BrowserRouter>
  </ThemeProvider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
