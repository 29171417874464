import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Moment from 'react-moment';
import Typography from '@material-ui/core/Typography';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 8,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minHeight: 400
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'justify',
        color: theme.palette.text.secondary,
    },
}));

export default function HappenedPlayers({ players, title }) {
    const classes = useStyles();
    const today = new Date();
    return (
        <Card className={classes.root}>
            <CardHeader
                title={title}
                subheader={<Moment format="D MMMM">{today}</Moment>}
            />
            <CardContent>
                <TableContainer className={classes.container}>
                    <Table stickyHeader size="small" aria-label="a dense table">
                        <TableBody>
                            {players.map((row) => (
                                <StyledTableRow key={row.ID}>
                                    <StyledTableCell>
                                        <Typography variant="subtitle2" color="textSecondary" component="p">
                                            <Moment locale='it' format="Y">{row.birth}</Moment>
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Typography variant="subtitle2" color="textSecondary" component="p">
                                            {row.name} {row.surname}
                                        </Typography>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    )
}
