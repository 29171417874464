import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import { alpha } from '@material-ui/core/styles';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import escareRegExp from 'escape-string-regexp'
import sortBy from 'sort-by'

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  }
}))(TableRow);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles((theme) => ({
  avatar: {
    backgroundColor: '#CA2331',
    color: '#FFFFFF',
  },
  avatarBlue: {
    backgroundColor: '#082D68',
    color: '#FFFFFF',
  },
  container: {
    maxHeight: 440,
  },
  header: {
    fontWeight: 'bold'
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  myTextStyle: {
    textDecoration: 'underline',
    '&:hover': {
      color: '#CA2331'
    },
    color: '#082D68'
  }
}));

export default function SeasonList({ seasons, onSeasonSelect }) {
  const classes = useStyles2();
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(0);
  const rowsPerPage = 4;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleQuery = (query) => {
    setQuery(query);
    setPage(0);
  };

  let filteredSeasons = seasons;
  if (query) {
    const searchMatch = new RegExp(escareRegExp(query), 'i');
    filteredSeasons = seasons.filter((season) => searchMatch.test(season.year));
    console.log(filteredSeasons, filteredSeasons);
  }
  filteredSeasons.sort(sortBy('-year'));
  return (
    <div className={classes.root}>
      <Typography variant='subtitle1' gutterBottom><b>ArchivioRossoBlu</b> nasce dalla passione per il <b>Cagliari Calcio</b> e le <b>statistiche</b> di un gruppo di ragazzi. Il sito contiene dati aggiornati dalla stagione <b>1947/1948</b> ad oggi.</Typography>
      <AppBar position="static">
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            Seleziona Stagione Cagliari Calcio
          </Typography>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SportsSoccerIcon />
            </div>
            <InputBase
              placeholder="Cerca.."
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              value={query}
              onChange={(event => handleQuery(event.target.value))}
            />
          </div>
        </Toolbar>
      </AppBar>
      <TableContainer className={classes.container} size="small" aria-label="a dense table">
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.header}>Serie</TableCell>
              <TableCell className={classes.header}>Anno</TableCell>
              <TableCell className={classes.header}>Punti</TableCell>
              <TableCell className={classes.header}>Posizione</TableCell>
              <TableCell className={classes.header}>V</TableCell>
              <TableCell className={classes.header}>P</TableCell>
              <TableCell className={classes.header}>S</TableCell>
              <TableCell className={classes.header}>GF</TableCell>
              <TableCell className={classes.header}>GS</TableCell>
              <TableCell className={classes.header}>DR</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredSeasons.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : filteredSeasons
            ).map((row) => (
              <StyledTableRow key={row.id} onClick={() => onSeasonSelect(row.id, row.year)}>
                <TableCell>
                  <Avatar aria-label="recipe" className={classes.avatar}>
                    {row.league}
                  </Avatar>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography className={classes.myTextStyle} variant="subtitle1" color="textSecondary" onClick={() => onSeasonSelect(row.id, row.year)}>
                    {row.year}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Avatar aria-label="recipe" className={classes.avatarBlue}>
                    {row.points}
                  </Avatar>
                </TableCell>
                <TableCell>
                  {(row.position > 0) ? (
                    <Typography variant="subtitle1" color="textSecondary" component="p">
                      {row.position}
                    </Typography>
                  ) : (
                    <Typography variant="subtitle1" color="textSecondary" component="p">
                      -
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" color="textSecondary" component="p">{row.v}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" color="textSecondary" component="p">{row.d}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" color="textSecondary" component="p">{row.l}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" color="textSecondary" component="p">{row.gf}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" color="textSecondary" component="p">{row.gs}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" color="textSecondary" component="p">{row.dr}</Typography>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10]}
                colSpan={10}
                count={filteredSeasons.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}