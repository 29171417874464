import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

import Moment from 'react-moment';
import 'moment/locale/it';
import { allCountries, allRoles } from '../../utils/generals'

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: "100%",
        minHeight: 400
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: '#082D68',
        color: '#FFFFFF',
    },
    cardAction: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
        backgroundColor: theme.palette.action.hover,
        minHeight: 75
    }
}));

export default function Players({ player, type, title }) {
    const classes = useStyles();
    const country = allCountries.filter(country => country.description === player.country);
    const imageCountry = `https://www.archiviorossoblu.it/images/nazioni/${country[0].flag}`;
    let imagePlayer = `https://www.archiviorossoblu.it/images/giocatori/${player.photo}`;
    const name = `${player.name} ${player.surname}`;
    let role = allRoles.filter((role) => role.description === player.role);
    player.roleabbrevation = role[0].role;
    //console.log(player);
    return (
        <Card className={classes.root}>
            <CardHeader
                avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>{player.roleabbrevation}</Avatar>
                }
                title={title}
                subheader={name}
            />
            <CardMedia
                className={classes.media}
                image={imagePlayer}
                title={name}
            />
            <CardContent>
                <div className='place-avatar' style={{
                    backgroundImage: `url(${imageCountry}`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundPositionX: 0,
                    margin: 0,
                    paddingLeft: 20,
                }}>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {player.birthplace} - <Moment locale='it' format="LL">{player.birth}</Moment>
                    </Typography>
                </div>
            </CardContent>
            <CardActions className={classes.cardAction}>
                <Typography variant="body2" color="textSecondary" component="p">{player.role}</Typography>
            </CardActions>
        </Card>
    );
}