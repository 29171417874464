import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Sidebar from './Sidebar.js'
import SearchMatchesDetails from './SearchMatchesDetails.js'
import Toolbar from '@material-ui/core/Toolbar';
import PeopleIcon from '@material-ui/icons/People';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Box, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'justify',
        color: theme.palette.text.secondary,
    },
    rightToolbar: {
        marginLeft: "auto",
        marginRight: -12
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
}

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

export default function WhoDetails() {
    const classes = useStyles();
    const valueTab = 0;

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <Sidebar />
                    <Tabs
                        variant="fullWidth"
                        value={valueTab}
                        aria-label="nav tabs example"
                    >
                        <LinkTab label="Squadre" icon={<PeopleIcon />} href="/matches" {...a11yProps(0)} />
                    </Tabs>
                    <Typography variant="h6" className={classes.rightToolbar}>
                        ArchivioRossoBlu
                    </Typography>
                </Toolbar>
            </AppBar>
            <TabPanel value={valueTab} index={0}>
                <Paper elevation={3}>
                    <SearchMatchesDetails />
                </Paper>
            </TabPanel>
        </div>
    )
}