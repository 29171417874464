import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles';

import * as ARBApi from '../utils/ARBApi'
import { Grid, Box } from '@material-ui/core';
import MatchReport from '../components/dialogs/MatchReport'
import { allPlayers } from '../utils/allPlayers'
import Skeleton from '@material-ui/lab/Skeleton';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import sortBy from 'sort-by'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { allTeams, allSeasons } from '../utils/generals'
import Moment from 'react-moment';
import 'moment/locale/it';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 12,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    }
}))(TableRow);

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    container: {
        maxHeight: 540,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'justify',
        color: theme.palette.text.secondary,
        paddingTop: 0,
        marginTop: 0
    },
    box: {
        margin: 0
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
    myTextStyle: {
        textDecoration: 'underline',
        '&:hover': {
            color: '#CA2331'
        },
        color: '#082D68'
    },
    avatar: {
        backgroundColor: '#CA2331',
        color: '#FFFFFF',
    },
    header: {
        fontWeight: 'bold'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 360,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    }
}));

export default function App() {
    const classes = useStyles();

    const [match, setMatch] = useState(0)
    const [matchOpen, setMatchOpen] = useState(false);
    const [page, setPage] = useState(0);
    const rowsPerPage = 5;

    const [teamId, setTeamId] = useState(0);
    const [matches, setMatches] = useState([]);

    const handleChange = (value) => {
        setTeamId(value);
        ARBApi.getAllByID(`matchesTeam`, value).then((matches) => {
            setMatches(matches);
        });
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleClickCloseMatch = () => {
        setMatchOpen(false);
        setMatch(0);
    };

    const onSelectMatch = (id) => {
        let [m] = matches.filter((match) => match.id === id);
        const [team] = (m.home === 'Cagliari') ? allTeams.filter((team) => team.name === m.away) : allTeams.filter((team) => team.name === m.home);
        m.opponentTeamId = (m.played === 'Y') ? 0 : team.id;
        m.title = (m.played === 'Y') ? `${m.home} ${m.gh} - ${m.ga} ${m.away}` : `${m.home} VS ${m.away}`;
        console.log(m);
        setMatch(m);
        setMatchOpen(true);
    };

    allTeams.sort(sortBy('name'));
    matches.map((match) => match.year = allSeasons.filter((season) => season.id === match.ID_S), [allSeasons]);
    console.log(matches);
    return (
        <Box component="span" m={1} className={classes.box}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    {(allPlayers.length > 0) ? (
                        <div className={classes.root}>
                            <AppBar position="static">
                                <Toolbar>
                                    <Typography className={classes.title} variant="h6" noWrap>
                                        Seleziona Squadra
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="age-native-helper">Squadra</InputLabel>
                                <NativeSelect
                                    value={teamId}
                                    onChange={(event => handleChange(event.target.value))}
                                    inputProps={{
                                        name: 'team',
                                        id: 'team-native-helper',
                                    }}
                                >
                                    <option aria-label="Seleziona..." value="" />
                                    {allTeams.filter((team) => team.name !== 'Cagliari').map((team) => (
                                        <option value={team.id} key={team.name}>{team.name}</option>
                                    ))}
                                </NativeSelect>
                            </FormControl>
                            <TableContainer className={classes.container}>
                                <Table stickyHeader size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell className={classes.header}>Stagione</StyledTableCell>
                                            <StyledTableCell className={classes.header}>Data</StyledTableCell>
                                            <StyledTableCell className={classes.header}>Partita</StyledTableCell>
                                            <StyledTableCell className={classes.header} align='center'>Dettagli</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(matches.length > 0
                                            ? matches.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : matches
                                        ).map((row) => (
                                            <StyledTableRow key={row.id} onClick={() => onSelectMatch(row.id)}>
                                                <StyledTableCell component="th" scope="row">
                                                    <Typography variant="subtitle1" color="textSecondary">
                                                        {row.year[0].year}
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <Typography variant="subtitle1" color="textSecondary">
                                                        {(row.date !== '0000-00-00 00:00:00') ? (<Moment locale='it' format="LL">{row.date}</Moment>) : <div>ND</div>}
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <Typography variant="subtitle1" color="textSecondary">
                                                        {row.home} {row.gh} - {row.ga} {row.away}
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell align='center'>
                                                    <IconButton aria-label="settings" onClick={() => onSelectMatch(row.id)}>
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            {(matches.length > 0) ? (
                                                <TablePagination
                                                    rowsPerPageOptions={[5]}
                                                    colSpan={10}
                                                    count={matches.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    SelectProps={{
                                                        inputProps: { 'aria-label': 'rows per page' },
                                                        native: true,
                                                    }}
                                                    onPageChange={handleChangePage}
                                                    ActionsComponent={TablePaginationActions}
                                                />
                                            ) : null}
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </div>
                    ) : (
                        <div>
                            <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
                            <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
                            <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
                            <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
                            <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
                        </div>
                    )
                    }
                </Grid>
            </Grid>
            {(matchOpen) ? (
                <MatchReport
                    open={matchOpen}
                    handleClose={handleClickCloseMatch}
                    title={match.title}
                    report={match.report}
                    played={match.played}
                    opponentTeamId={match.opponentTeamId}
                />
            ) : null}
        </Box>
    )
}