import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Tabs, Tab, Box } from '@material-ui/core';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import PeopleIcon from '@material-ui/icons/People';
import EventIcon from '@material-ui/icons/Event';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import Toolbar from '@material-ui/core/Toolbar';
import PersonIcon from '@material-ui/icons/Person';
import SportsIcon from '@material-ui/icons/Sports';
import SeasonList from './SeasonList.js'
import Players from './Players.js'
import Matches from './Matches.js'
import CupMatches from './CupMatches.js'
import Coaches from './Coaches.js'
import Referees from './Referees.js'
import Sidebar from './Sidebar.js'
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  rightToolbar: {
    marginLeft: "auto",
    marginRight: -12
  },
  leftToolbar: {
    marginRight: "auto",
    marginLeft: 0,
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

export default function SeasonDetails({
  players,
  matches,
  cupMatches,
  seasonID,
  seasons,
  countries,
  coaches,
  referees,
  onSeasonSelect,
  onMatchSelect,
  onPlayerSelect,
  seasonLabel,
  roles }) {
  const classes = useStyles();
  const [valueTab, setValueTab] = useState(0);

  useEffect(() => {
    if (seasonID > 0) {
      setValueTab(1);
    }
  }, [seasonID]);

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };
  let tabEnabled = true;

  if (seasonID > 0) {
    tabEnabled = false;
  }

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Sidebar />
          <Tabs
            variant="scrollable"
            value={valueTab}
            onChange={handleChange}
            aria-label="scrollable auto tabs example"
            indicatorColor="primary"
          >
            <LinkTab label="Stagioni" icon={<SportsSoccerIcon />} href="/home" {...a11yProps(0)} />
            <LinkTab label="Rosa" icon={<PeopleIcon />} href="/players" {...a11yProps(1)} disabled={tabEnabled} />
            <LinkTab label="Campionato" icon={<EventIcon />} href="/matches" {...a11yProps(2)} disabled={tabEnabled} />
            <LinkTab label="Coppa" icon={<EmojiEventsIcon />} href="/cupmatches" {...a11yProps(3)} disabled={tabEnabled} />
            <LinkTab label="Allenatore/i" icon={<PersonIcon />} href="/coaches" {...a11yProps(4)} disabled={tabEnabled} />
            <LinkTab label="Arbitri" icon={<SportsIcon />} href="/coaches" {...a11yProps(5)} disabled={tabEnabled} />

          </Tabs>
          <Typography variant="h6" className={classes.rightToolbar}>
            ArchivioRossoBlu
          </Typography>
        </Toolbar>
      </AppBar>
      <TabPanel value={valueTab} index={0}>
        <SeasonList
          seasons={seasons}
          onSeasonSelect={onSeasonSelect}
        />
      </TabPanel>
      <TabPanel value={valueTab} index={1} selected>
        <Players
          players={players}
          seasonID={seasonID}
          seasonLabel={seasonLabel}
          countries={countries}
          roles={roles}
          onPlayerSelect={onPlayerSelect}
        />
      </TabPanel>
      <TabPanel value={valueTab} index={2}>
        <Matches
          matches={matches}
          seasonID={seasonID}
          seasonLabel={seasonLabel}
          onMatchSelect={onMatchSelect}
        />
      </TabPanel>
      <TabPanel value={valueTab} index={3}>
        <CupMatches matches={cupMatches} seasonID={seasonID} seasonLabel={seasonLabel}></CupMatches>
      </TabPanel>
      <TabPanel value={valueTab} index={4}>
        <Coaches coaches={coaches} seasonID={seasonID} seasonLabel={seasonLabel}></Coaches>
      </TabPanel>
      <TabPanel value={valueTab} index={5}>
        <Referees referees={referees} seasonID={seasonID} seasonLabel={seasonLabel}></Referees>
      </TabPanel>
    </div>
  );
}