import React, { useState, Fragment } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MuiListItemText from '@material-ui/core/ListItemText'
import EqualizerIcon from '@material-ui/icons/Equalizer';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import ScheduleIcon from '@material-ui/icons/Schedule';
import FacebookIcon from '@material-ui/icons/Facebook'
import InfoIcon from '@material-ui/icons/Info';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import PeopleIcon from '@material-ui/icons/People';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    list: {
        width: 250,
        backgroundColor: "#FFFFFF"
    },
    fullList: {
        width: 'auto',
    },
    title: {
        color: "#082D68",
        textAlign: "center",
        fontWeight: "bold"
    },
    facebookIcon: {
        color: theme.palette.common.facebook,
    },
    twitterIcon: {
        color: theme.palette.common.twitter,
    },
    linkedinIcon: {
        color: theme.palette.common.linkedin,
    },
    shareContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        margin: 'auto 0 0 0',
        padding: theme.spacing(1),
    },
}));

const ListItemText = ({ primary, secondary }) => (
    <MuiListItemText
        primary={primary}
        secondary={secondary}
        secondaryTypographyProps={{
            component: 'span',
            variant: 'caption',
        }}
    />
)

export default function Sidebar() {
    const classes = useStyles();

    const [sidebar, setSidebar] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setSidebar(open);
    };
    return (
        <Fragment>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer(true)}
                edge="start"
            >
                <MenuIcon />
            </IconButton>
            <SwipeableDrawer
                open={sidebar}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                <div
                    className={classes.list}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <Typography variant="h6" className={classes.title}>
                        ArchivioRossoBlu
                    </Typography>
                    <Divider />
                    <List>
                        <ListItem button key="Stagioni" component="a" href={`/stagioni`}>
                            <ListItemIcon dense='true' button='true'><SportsSoccerIcon color="secondary" /></ListItemIcon>
                            <ListItemText
                                primary="Stagioni"
                                secondary="consulta stagioni"
                            />
                        </ListItem>
                        <ListItem button key="Statistiche" component="a" href={`/statistiche`}>
                            <ListItemIcon dense='true' button='true'><EqualizerIcon color="secondary" /></ListItemIcon>
                            <ListItemText
                                primary="Statistiche"
                                secondary="consulta dati storici"
                            />
                        </ListItem>
                        <ListItem button key="Giocatori" component="a" href={`/giocatori`}>
                            <ListItemIcon dense='true' button='true'><PeopleIcon color="secondary" /></ListItemIcon>
                            <ListItemText
                                primary="Giocatori"
                                secondary="ricerca giocatori"
                            />
                        </ListItem>
                        <ListItem button key="Partite" component="a" href={`/partite`}>
                            <ListItemIcon dense='true' button='true'><ScheduleIcon color="secondary" /></ListItemIcon>
                            <ListItemText
                                primary="Partite"
                                secondary="ricerca partite"
                            />
                        </ListItem>
                        <ListItem button key="ChiSiamo" component="a" href={`#`}>
                            <ListItemIcon dense='true' button='true'><InfoIcon color="secondary" /></ListItemIcon>
                            <ListItemText
                                primary="Chi siamo"
                                secondary="qualcosa su di noi"
                            />
                        </ListItem>
                        <ListItem button key="Email" component="a" href={`mailto:info@archiviorossoblu.com`}>
                            <ListItemIcon dense='true' button='true'><AlternateEmailIcon color="secondary" /></ListItemIcon>
                            <ListItemText
                                primary="Contatti"
                                secondary="Scrivici a info@archiviorossoblu.com"
                            />
                        </ListItem>
                    </List>
                    <Divider />
                    <Box className={classes.shareContainer}>
                        <IconButton href="https://www.facebook.com/archiviorossoblu.it" target="_blank">
                            <FacebookIcon className={classes.facebookIcon} />
                        </IconButton>
                        <IconButton href="https://twitter.com/archiviorossobl" target="_blank">
                            <TwitterIcon className={classes.twitterIcon} />
                        </IconButton>
                        <IconButton href="https://www.linkedin.com/in/matteomedda/" target="_blank">
                            <LinkedInIcon className={classes.linkedinIcon} />
                        </IconButton>
                        <IconButton href="https://www.linkedin.com/in/nicola-adamu-5443a429/" target="_blank">
                            <LinkedInIcon className={classes.linkedinIcon} />
                        </IconButton>

                    </Box>
                </div>
            </SwipeableDrawer>
        </Fragment >
    )
}