import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import EventIcon from '@material-ui/icons/Event';
import Badge from '@material-ui/core/Badge';
import Moment from 'react-moment';
import 'moment/locale/it';
import { allCountries } from '../../utils/generals'
import StatsList from '../dialogs/StatsList'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    minHeight: 400
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: '#082D68',
    color: '#FFFFFF',
  },
  cardAction: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    backgroundColor: theme.palette.action.hover,
    minHeight: 75
  }
}));

export default function Players({ players, type }) {
  const classes = useStyles();
  const player = players[0];
  const country = allCountries.filter(country => country.description === player.country);
  const imageCountry = `https://www.archiviorossoblu.it/images/nazioni/${country[0].flag}`;
  let imagePlayer = `https://www.archiviorossoblu.it/images/giocatori/${player.photo}`;
  if (type === 'Allenatori') {
    imagePlayer = `https://www.archiviorossoblu.it/images/allenatori/${player.photo}`;
    player.role = 'Allenatore';
  }
  const name = `${player.name} ${player.surname}`
  const title = `TOP ${type}`;

  const [openStatList, setOpenStatList] = useState(false);

  const handleClickOpen = () => {
    setOpenStatList(true);
  };
  const handleClose = () => {
    setOpenStatList(false);
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <IconButton aria-label="settings" onClick={handleClickOpen}>
            <MoreVertIcon />
          </IconButton>
        }
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>1</Avatar>
        }
        title={title}
        subheader={name}
      />
      <CardMedia
        className={classes.media}
        image={imagePlayer}
        title={name}
      />
      <CardContent>
        <div className='place-avatar' style={{
          backgroundImage: `url(${imageCountry}`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundPositionX: 0,
          margin: 0,
          paddingLeft: 20,
        }}>
          <Typography variant="body2" color="textSecondary" component="p">
            {player.birthplace} - <Moment locale='it' format="LL">{player.date}</Moment>
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">{player.role}</Typography>
        </div>
      </CardContent>
      <CardActions className={classes.cardAction}>
        {(type === 'Gol' || type === 'Gol Subiti' ? (
          <Badge
            badgeContent={player.value}
            {...{
              color: 'primary',
              children: <SportsSoccerIcon />,
            }}
            max={1000}
          />
        ) : (
            <Badge
              badgeContent={player.value}
              {...{
                color: 'primary',
                children: <EventIcon />,
              }}
              max={1000}
            />)
        )}
      </CardActions>
      <StatsList
        open={openStatList}
        onClickClose={handleClose}
        title={name}
        type={type}
        players={players}
      />
    </Card>
  );
}