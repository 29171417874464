import React, { useState } from 'react';
import SeasonDetails from '../components/SeasonDetails.js'
import Footer from '../components/Footer'
import CardGrids from '../components/cards/CardGrids'
import { makeStyles } from '@material-ui/core/styles'
import * as ARBApi from '../utils/ARBApi'
import { Grid, Box } from '@material-ui/core';
import MatchReport from '../components/dialogs/MatchReport'
import PlayerReport from '../components/dialogs/PlayerReport'
import { allTeams, allSeasons } from '../utils/generals'
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'justify',
    color: theme.palette.text.secondary,
    paddingTop: 0,
    marginTop: 0
  },
  box: {
    margin: 0
  }
}));

export default function App() {
  const classes = useStyles();

  const [seasonID, setSeasonID] = useState(0);
  const [seasonLabel, setSeasonLabel] = useState('');
  const [players, setPlayers] = useState([]);
  const [playersSeasons, setPlayerSeasons] = useState([]);
  const [matches, setMatches] = useState([]);
  const [cupMatches, setCupMatches] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const [referees, setReferees] = useState([]);
  const [openPlayer, setPlayerOpen] = useState(false);
  const [playerId, setPlayerId] = useState(0)
  const [playerReport, setPlayerReport] = useState([]);
  const [openMatch, setMatchOpen] = useState(false);
  const [match, setMatch] = useState({
    id: 0,
    played: 'N',
    report: '',
    opponentTeamId: 0,
    title: '',
  });

  const changeSeason = (seasonID, year) => {
    if (seasonID > 0) {
      setPlayers([]);
      setPlayerSeasons([]);
      setMatches([]);
      setCupMatches([]);
      setCoaches([]);
      setReferees([]);
      ARBApi.getAllByID(`players`, seasonID).then((players) => {
        if (typeof players !== "undefined") {
          setPlayers(players.players);
          setPlayerSeasons(players.seasons);
        }
      });
      ARBApi.getAllByID(`matches`, seasonID).then((matches) => {
        setMatches(matches);
      });
      ARBApi.getAllByID(`cupMatches`, seasonID).then((cupMatches) => {
        setCupMatches(cupMatches);
      });
      ARBApi.getAllByID(`coaches`, seasonID).then((coaches) => {
        setCoaches(coaches);
      });
      ARBApi.getAllByID(`referees`, seasonID).then((referees) => {
        setReferees(referees);
      });
      setSeasonID(seasonID);
      setSeasonLabel(year);
    }

  }

  const changePlayer = (id) => {
    if (id > 0) {
      const [data] = players.filter((player) => player.id === id);
      const seasons = playersSeasons[id]
      setPlayerReport({
        data,
        seasons
      })
      setPlayerId(id);
      setPlayerOpen(true);
    }
  };

  const changeMatch = (id) => {
    let [m] = matches.filter((match) => match.id === id);
    const [team] = (m.home === 'Cagliari') ? allTeams.filter((team) => team.name === m.away) : allTeams.filter((team) => team.name === m.home);
    m.opponentTeamId = (m.played === 'Y') ? 0 : team.id;
    m.title = (m.played === 'Y') ? `${m.home} ${m.gh} - ${m.ga} ${m.away}` : `${m.home} VS ${m.away}`;
    setMatch(m);
    setMatchOpen(true);
  };

  const handleClickCloseMatch = () => {
    setMatchOpen(false);
    setMatch([]);
  };

  const handleClickClosePlayer = () => {
    setPlayerOpen(false);
    setPlayerId(0);
  };

  return (
    <Box component="span" m={1} className={classes.box}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {(allSeasons.length > 0) ? (
            <SeasonDetails
              players={players}
              matches={matches}
              cupMatches={cupMatches}
              seasons={allSeasons}
              coaches={coaches}
              referees={referees}
              seasonID={seasonID}
              seasonLabel={seasonLabel}
              onSeasonSelect={changeSeason}
              onMatchSelect={changeMatch}
              onPlayerSelect={changePlayer}
            />
          ) : (
            <div>
              <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
              <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
              <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
              <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
              <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
            </div>
          )
          }
        </Grid>
        <CardGrids />
        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
      {(openMatch > 0) ? (
        <MatchReport
          open={openMatch}
          handleClose={handleClickCloseMatch}
          title={match.title}
          report={match.report}
          played={match.played}
          opponentTeamId={match.opponentTeamId}
        />
      ) : null}
      {(openPlayer) ? (
        <PlayerReport
          open={openPlayer}
          handleClose={handleClickClosePlayer}
          playerReport={playerReport}
          playerId={playerId}
        />
      ) : null}
    </Box>
  )
}