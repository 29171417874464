import React from 'react';
import Waiting from './cards/Waiting.js'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Paper, Typography } from '@material-ui/core';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    root: {
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        width: '100%'
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    title: {
        color: '#FFFFFF',
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.9) 70%, rgba(0,0,0,0) 100%)',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'justify',
        color: theme.palette.text.secondary,
    },
    stats: {
        color: '#082D68'
    }
}));

export default function StatsPlayers({ players, items, title, openDialog, type, openDialogAll, column }) {
    const classes = useStyles();

    return (
        <Paper elevation={3} className={classes.paper}>
            <Typography variant='h6'>{title}
                <Tooltip title="Statistica completa" arrow>
                    <IconButton
                        onClick={() => openDialogAll(type, column)}
                        color="primary"
                    >
                        <EqualizerIcon
                        />
                    </IconButton>
                </Tooltip>
            </Typography>
            <div className={classes.root}>
                {players.length > 0 ? (
                    <GridList className={classes.gridList} cols={3.5}>
                        {players.slice(0, items).map((player) => (
                            <GridListTile key={player.id}>
                                <img src={player.image} alt={player.name} />
                                {type !== 'coaches' ? (
                                    <GridListTileBar
                                        title={` ${player.value} - ${player.name} ${player.surname}`}
                                        classes={{
                                            root: classes.titleBar,
                                            title: classes.title,
                                        }}
                                        actionIcon={
                                            <IconButton aria-label={`star ${player.name} ${player.surname}`} onClick={() => openDialog(player.id, type)}>
                                                <EqualizerIcon className={classes.title} />
                                            </IconButton>
                                        }
                                    />
                                ) : (
                                        <GridListTileBar
                                            title={`${player.name} ${player.surname} - ${player.value}`}
                                            classes={{
                                                root: classes.titleBar,
                                                title: classes.title,
                                            }}
                                        />
                                    )}
                            </GridListTile>
                        ))}
                    </GridList>
                ) : (
                        <Grid item xs={12}>
                            <Waiting />
                        </Grid>
                    )}
            </div>
        </Paper>
    )
}