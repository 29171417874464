import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import * as ARBApi from './../utils/ARBApi'
import StatsSlider from './StatsSlider';
import PlayerReport from './dialogs/PlayerReport';
import StatsList from './dialogs/StatsList';

const useStyles = makeStyles((theme) => ({
    root: {
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        width: '100%'
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    title: {
        color: '#FFFFFF',
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.9) 70%, rgba(0,0,0,0) 100%)',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'justify',
        color: theme.palette.text.secondary,
    }
}));

export default function StatsPlayers() {
    const classes = useStyles();
    const [appareances, setAppareances] = useState([]);
    const [appareancesSeasons, setAppareancesSeasons] = useState([]);
    const [goalsSeasons, setGoalsSeasons] = useState([]);
    const [goalkeeperSeasons, setGoalkeepersSeasons] = useState([]);
    const [goals, setGoals] = useState([]);
    const [coaches, setCoaches] = useState([]);
    const [goalkeepers, setGoalkeepers] = useState([]);
    const [openPlayer, setOpenPlayer] = useState(false);
    const [playerId, setPlayerId] = useState(0);
    const [playerReport, setPlayerReport] = useState([]);
    const [openStatList, setOpenStatList] = useState(false);
    const [list, setList] = useState([]);
    const [typeList, setTypeList] = useState(false);
    const [titleList, setTitleList] = useState('');
    const [titleColumn, setTitleColumn] = useState('');

    const items = 10;

    const handleOpenDialogAll = (type, column) => {
        switch (type) {
            case 'appareances':
                setList(appareances);
                setTypeList(type);
                setTitleList("Presenze giocatori");
                break;
            case 'goals':
                setList(goals);
                setTypeList(type);
                setTitleList("Gol giocatori");
                break;
            case 'goalkeepers':
                setList(goalkeepers);
                setTypeList(type);
                setTitleList("Gol subiti portieri");
                break;
            case 'coaches':
                setList(coaches);
                setTypeList(type);
                setTitleList("Presenze allenatori");
                break;
            default:
                setList(appareances);
                setTypeList("Presenze");
                setTitleList("Presenze giocatori");
        }
        setTitleColumn(column);
        setOpenStatList(true);
    }

    const handleCloseList = () => {
        setOpenStatList(false);
        setList([]);
    }

    const handleClickClosePlayer = () => {
        setOpenPlayer(false);
        setPlayerId(0);
    };

    const handleOpenPlayer = (id, type) => {
        let data = [];
        let seasons = [];
        if (id > 0) {

            switch (type) {
                case 'apparenaces':
                    [data] = appareances.filter((player) => player.id === id);
                    seasons = appareancesSeasons[id];
                    break;
                case 'goals':
                    [data] = goals.filter((player) => player.id === id);
                    seasons = goalsSeasons[id];
                    break;
                case 'goalkeepers':
                    [data] = goalkeepers.filter((player) => player.id === id);
                    seasons = goalkeeperSeasons[id];
                    break;
                default:
                    [data] = appareances.filter((player) => player.id === id);
                    seasons = appareancesSeasons[id];
            }
        }
        setPlayerReport({
            data,
            seasons
        })
        setOpenPlayer(true);
        setPlayerId(id);
    };

    useEffect(() => {
        ARBApi.getAll(`appareancesReport`).then((appareances) => {
            console.log("appareancesReport", appareances);
            setAppareances(appareances.players);
            setAppareancesSeasons(appareances.seasons);
        });
        ARBApi.getAll(`goalsReport`).then((goals) => {
            setGoals(goals.players);
            setGoalsSeasons(goals.seasons);
        });
        ARBApi.getAll(`coachesAppareances`).then((coaches) => {
            setCoaches(coaches);
        });
        ARBApi.getAll(`goalkeepersReport`).then((goalkeepers) => {
            setGoalkeepers(goalkeepers.players);
            setGoalkeepersSeasons(goalkeepers.seasons);
        });
    }, []);

    console.log("apparences", appareances);
    appareances.map((player) => player.image = player.photo ? `https://www.archiviorossoblu.it/images/giocatori/${player.photo}` : `https://www.archiviorossoblu.it/images/notfound.png`);
    goals.map((player) => player.image = player.photo ? `https://www.archiviorossoblu.it/images/giocatori/${player.photo}` : `https://www.archiviorossoblu.it/images/notfound.png`);
    goalkeepers.map((player) => player.image = player.photo ? `https://www.archiviorossoblu.it/images/giocatori/${player.photo}` : `https://www.archiviorossoblu.it/images/notfound.png`);
    coaches.map((player) => player.image = player.photo ? `https://www.archiviorossoblu.it/images/allenatori/${player.photo}` : `https://www.archiviorossoblu.it/images/notfound.png`);

    return (
        <div className={classes.root}>
            <StatsSlider
                players={appareances}
                items={items}
                title={`TOP #${items} Presenze`}
                openDialog={handleOpenPlayer}
                type='appareances'
                column='presenze'
                openDialogAll={handleOpenDialogAll}
            />
            <StatsSlider
                players={goals}
                items={items}
                title={`TOP #${items} Gol`}
                type='goals'
                column='gol'
                openDialog={handleOpenPlayer}
                openDialogAll={handleOpenDialogAll}
            />
            <StatsSlider
                players={goalkeepers}
                items={items}
                title={`TOP #${items} Gol subiti`}
                type='goalkeepers'
                column='gol subiti'
                openDialog={handleOpenPlayer}
                openDialogAll={handleOpenDialogAll}
            />
            <StatsSlider
                players={coaches}
                items={items}
                title={`TOP #${items} Allenatori`}
                type='coaches'
                column='presenze'
                openDialogAll={handleOpenDialogAll}
            />
            {(openPlayer > 0) ? (
                <PlayerReport
                    open={openPlayer}
                    handleClose={handleClickClosePlayer}
                    playerId={playerId}
                    playerReport={playerReport}
                    openDialogAll={handleOpenDialogAll}
                />
            ) : null}
            {(openStatList > 0) ? (
                <StatsList
                    open={openStatList}
                    onClickClose={handleCloseList}
                    title={'Statistica Completa'}
                    type={typeList}
                    players={list}
                    forceTitle={titleList}
                    titleColumn={titleColumn}
                />
            ) : null}
        </div>
    )
}