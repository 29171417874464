import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Moment from 'react-moment';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: 440,
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  header: {
    fontWeight: 'bold'
  },
  avatarBlue: {
    backgroundColor: '#082D68',
    color: '#FFFFFF',
  }
})
);

export default function CupMatches({ matches, seasonID, seasonLabel }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            Coppa Stagione {seasonLabel}
          </Typography>
        </Toolbar>
      </AppBar>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell className={classes.header}>Turno</StyledTableCell>
              <StyledTableCell className={classes.header}>Data</StyledTableCell>
              <StyledTableCell className={classes.header}>Partita</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {matches.length > 0 ? (
              matches.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell>
                    <Typography variant="subtitle1" color="textSecondary" component="p">{row.description}</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="subtitle1" color="textSecondary" component="p">
                      <Moment locale='it' format="LL">{row.date}</Moment>
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    {(row.played === 'Y') ? (
                      <Typography variant="subtitle1" color="textSecondary" component="p">
                        {row.home} {row.gh} - {row.ga} {row.away}
                      </Typography>
                    ) : (
                        <Typography variant="subtitle1" color="textSecondary" component="p">
                          {row.home} VS {row.away}
                        </Typography>
                      )}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
                seasonID.length > 0 ? (
                  <StyledTableRow key='none'>
                    <StyledTableCell align="right" colSpan={4}>
                      <LinearProgress />
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                    <StyledTableRow key='none'>
                      <StyledTableCell align="right" colSpan={4}>Seleziona stagione.</StyledTableCell>
                    </StyledTableRow>
                  ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
