import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { allCountries } from '../../utils/generals'
import Moment from 'react-moment';

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FFFFFF",
    color: "#082D68"
  },
  avatar: {
    backgroundColor: '#CA2331',
    color: '#FFFFFF',
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  }
}))(TableRow);

export default function StatsList({ open, onClickClose, type, players, forceTitle, titleColumn }) {
  const classes = useStyles();
  const descriptionElementRef = useRef(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const getFlag = (flag) => {
    const country = allCountries.filter(country => country.description === flag);
    return country[0].flag;
  }

  let title = forceTitle;
  if (forceTitle !== '') {
    switch (type) {
      case 'Gol':
        title = 'Classifica Marcatori';
        break;
      case 'Gol Subiti':
        title = 'Classifica Gol Subiti';
        break;
      case 'Allenatori':
        title = 'Classifica Presenze Allenatori';
        break;
      default:
        title = 'Classifica Presenze';
    }
  }

  const column = (typeof titleColumn !== 'undefined') ? titleColumn : type;
  return (
    <Dialog
      open={open}
      onClose={onClickClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      className={classes.dialog}
    >
      <DialogTitle id="scroll-dialog-title" className={classes.title}>
        {title} - TOP #{players.length}
      </DialogTitle>
      <DialogContent>

        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {type !== 'coaches' ? (
                  <StyledTableCell className={classes.header} align='left'>Giocatore</StyledTableCell>
                ) : (
                    <StyledTableCell className={classes.header} align='left'>Allenatore</StyledTableCell>
                  )}
                <StyledTableCell className={classes.header} align='center'>DataNascita</StyledTableCell>
                <StyledTableCell className={classes.header} align='center'>{column}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {players.length > 0 ? (
                players.map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell component="th" scope="row" align='left'>
                      <div className='place-avatar' style={{
                        backgroundImage: `url(https://www.archiviorossoblu.it/images/nazioni/${getFlag(row.country)})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundPositionX: 0,
                        margin: 0,
                        paddingLeft: 20,
                      }}>
                        <Typography variant="subtitle1">{row.surname} {row.name}</Typography>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography variant="subtitle1" color="textSecondary" component="p">
                        <Moment locale='it' format="L">{row.date}</Moment>
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align='left'>
                      <Avatar aria-label="recipe" className={classes.avatar}>{row.value}</Avatar>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                  <StyledTableRow key='none'>
                    <StyledTableCell align="right" colSpan={7}>
                      <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
                      <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
                      <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
                      <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
                      <Skeleton animation="wave" height={40} width="100%" style={{ marginBottom: 6 }} />
                    </StyledTableCell>
                  </StyledTableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>

      </DialogContent>
      <DialogActions>
        <Button onClick={onClickClose} color="primary">
          Chiudi
          </Button>
      </DialogActions>
    </Dialog>
  );
}