import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import { allTeams } from '../../utils/generals'
import MatchReport from '../dialogs/MatchReport'

import Moment from 'react-moment';
import 'moment/locale/it';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: '#082D68',
    color: '#FFFFFF',
  },
  cardAction: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    backgroundColor: theme.palette.action.hover,
    minHeight: 75
  }
}));

export default function Match({ match, title }) {
  const classes = useStyles();

  const matchTitle = (match.played === 'Y') ? `${match.home} ${match.goalshome} - ${match.goalsaway} ${match.away}` : `${match.home} VS ${match.away}`;
  const homeTeam = allTeams.filter((team) => team.name === match.home);
  const awayTeam = allTeams.filter((team) => team.name === match.away);

  const imageHome = `https://www.archiviorossoblu.it/images/squadre/${homeTeam[0].photo}`;
  const imageAway = `https://www.archiviorossoblu.it/images/squadre/${awayTeam[0].photo}`;
  const image = (match.photo !== '') ? `https://www.archiviorossoblu.it/images/partite/${match.photo}` : 'https://www.archiviorossoblu.it/images/sfondo-campo.jpg';

  const [openLastMatch, setLastMatchOpen] = useState(false);

  const handleClickOpen = () => {
    setLastMatchOpen(true);
  };
  const handleClose = () => {
    setLastMatchOpen(false);
  };
  const opponentTeamId = (homeTeam[0].id === "13") ? awayTeam[0].id : homeTeam[0].id;

  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <IconButton aria-label="settings" onClick={handleClickOpen}>
            <MoreVertIcon
            />
          </IconButton>
        }
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {match.day}
          </Avatar>
        }
        title={title}
        subheader={
          <Moment locale='it' format="LL">{match.date}</Moment>
        }
      />
      <CardMedia
        className={classes.media}
        image={image}
        title={title}
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {matchTitle}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Stadio: {homeTeam[0].stadium}
        </Typography>
      </CardContent>
      <CardActions className={classes.cardAction}>
        <Avatar alt={match.home} src={imageHome} />
        <Avatar alt={match.away} src={imageAway} />
      </CardActions>
      <MatchReport
        open={openLastMatch}
        handleClose={handleClose}
        title={matchTitle}
        report={match.report}
        played={match.played}
        opponentTeamId={opponentTeamId}
      />
    </Card>
  );
}