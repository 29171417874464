import React, { useEffect } from 'react';
import Sidebar from './../components/Sidebar.js'
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Footer from '../components/Footer';

const addScript = ({ src, id, onLoad }) => {
    const existing = document.getElementById(id);
    if (existing) {
        return existing;
    } else {
        const script = document.createElement("script");
        script.src = src;
        script.id = id;
        script.async = true;
        script.onload = () => {
            if (onLoad) {
                onLoad();
            }
        };
        document.getElementById("container").appendChild(script);
        return script;
    }
};

const removeScript = ({ id }) => {
    const script = document.getElementById(id);
    if (script) {
        document.body.removeChild(script);
    }
};

export default function Privacy() {
    useEffect(() => {
        const script = addScript({
            src: `https://consent.cookiebot.com/ffb86860-e5b9-4c85-99cd-a5b293eaae07/cd.js`,
            id: "privacy-script",
            onLoad: () => {
                console.log("Script loaded!");
            },
        });
        return () => removeScript({ id: script.id });
    }, []);

    return (
        <div>
            <AppBar position="static">
                <Toolbar>
                    <Sidebar />
                </Toolbar>
            </AppBar>
            <Paper>
                <div id="container"></div>
            </Paper>
            <Footer></Footer>
        </div>
    )
}

