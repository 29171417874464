import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
  },
  card: {
    maxWidth: "100%",
    margin: theme.spacing(2),
  },
  media: {
    height: 190,
  },
  cardAction:{
    height: 80,
  }
}));

export default function CardWaiting() {
   const classes = useStyles();
   return (
    <Card className={classes.root}>
      <CardHeader
        avatar={<Skeleton animation="wave" variant="circle" width={40} height={40} />}
        title={<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />}
        subheader={<Skeleton animation="wave" height={10} width="40%" />}
      />
      <Skeleton animation="wave" variant="rect" className={classes.media} />
      <CardContent>
        <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
      </CardContent>
      <CardActions className={classes.cardAction}>
        <Skeleton animation="wave" variant="circle" width={40} height={40} />
        <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
      </CardActions>
    </Card>
  );
}