import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { Paper, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { allTeams } from '../utils/generals'
import MatchReport from '../components/dialogs/MatchReport'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    title: {
        color: '#FFFFFF',
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.9) 70%, rgba(0,0,0,0) 100%)',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'justify',
        color: theme.palette.text.secondary,
    }
}));

export default function StatsTeams() {
    const classes = useStyles();
    const [openMatch, setMatchOpen] = useState(false);
    const [opponentTeamId, setOpponentTeamId] = useState(0);
    const [opponentTeam, setopponentTeam] = useState('');
    const items = 10;

    const handleClickCloseMatch = () => {
        setMatchOpen(false);
        setOpponentTeamId(0);
        setopponentTeam('');
    };

    const changeMatch = (id, team) => {
        if (id > 0) {
            setMatchOpen(true);
            setOpponentTeamId(id);
            setopponentTeam(team);
        }
    };

    const teamsAvailable = allTeams.filter((team) => (team.photostadium !== '' && team.name !== 'Cagliari'));
    teamsAvailable.map((team) => team.image = `https://www.archiviorossoblu.it/images/squadre/stadi/${team.photostadium}`);

    return (
        <Paper elevatin={3} className={classes.paper}>
            <Typography variant='h6'>TOP #5 squadre affrontate</Typography>
            <div className={classes.root}>
                <GridList className={classes.gridList} cols={3.5}>
                    {teamsAvailable.slice(0, items).map((team) => (
                        <GridListTile key={team.id}>
                            <img src={team.image} alt={team.name} />
                            <GridListTileBar
                                title={`${team.name} - Partite Giocate: ${team.matches}`}
                                classes={{
                                    root: classes.titleBar,
                                    title: classes.title,
                                }}
                                actionIcon={
                                    <IconButton aria-label={`star ${team.name}`} onClick={() => changeMatch(team.id, team.name)}>
                                        <EqualizerIcon className={classes.title} />
                                    </IconButton>
                                }
                            />
                        </GridListTile>
                    ))}
                </GridList>
            </div>
            {(openMatch > 0) ? (
                <MatchReport
                    open={openMatch}
                    handleClose={handleClickCloseMatch}
                    title={`vs ${opponentTeam}`}
                    report={''}
                    played={'N'}
                    opponentTeamId={opponentTeamId}
                />
            ) : null}
        </Paper>
    );
}